import { Announcement, MenuHeader } from '../../connected';
import React, { FC } from 'react';
import Footer from '../Footer';
import { Layout } from 'antd';
import SideMenu from '../SideMenu';
import styles from './styles.module.less';

interface Props {
  children: React.ReactNode,
  menuHeaderChildren: JSX.Element,
  show?: boolean,
}

const SiteLayout: FC<Props> = ({ children, menuHeaderChildren, show }) => {

  if (!show) {
    return (
      <div className={styles.siteLayout}>
        {children}
      </div>
    );
  }

  return (
    <>
      <Announcement />
      <Layout hasSider>
        <SideMenu/>
        <Layout className={styles.siteLayout}>
          <MenuHeader>
            {menuHeaderChildren}
          </MenuHeader>
          {children}
          <Footer/>
        </Layout>
      </Layout>
    </>
  );
};
export default SiteLayout;
