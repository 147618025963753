import React, { FC } from 'react';
import { Spin } from 'antd';
import styles from './styles.module.less';

interface Props {
  tip?: React.ReactNode
  icon?: JSX.Element
  style?: React.CSSProperties
}

const Spinner: FC<Props> = ({ tip, style, icon }) => {
  return (
    <div className={styles.container} style={style}>
      {icon ? <Spin tip={tip} indicator={icon}/> :  <Spin tip={tip} />}
    </div>
  );
};
export default Spinner;
