import analytics from './Analytics';
import client from './Client';

export async function patchObservationDate(
  tenant: string,
  projectCode: string,
  observationID: string,
  date: Date,
) {

  try {
    analytics.trackEvent('Observation Edited');
    await client.server.patch(
      `/scratch/telemetry/observations/${tenant}/${projectCode}/${observationID}/date`,
      {
        date,
      },
    );
  } catch (error) {
    throw error;
  }
}

export async function deleteObservation(
  tenant: string,
  projectCode: string,
  observationID: string,
) {
  analytics.trackEvent('Observation Deleted');
  try {
    await client.server.delete(
      `/scratch/telemetry/observations/${tenant}/${projectCode}/${observationID}`,
    );
  } catch (error) {
    throw error;
  }
}