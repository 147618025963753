import { Button, Col, DatePicker, Row } from 'antd';
import { FilterValue, Key } from 'antd/es/table/interface';
import React, { FC, useEffect } from 'react';
import {
  convertTimezoneTimeToLocalizedTime,
  disabledDate,
  getProjectTimezone,
  getRangePresets,
  toProjectDate,
} from '../../../utils';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import styles from './styles.module.less';

dayjs.extend(customParseFormat);

interface Props {
  selectedKeys: FilterValue | null
  setSelectedKeys: (keys: Key[]) => void
  confirm: () => void
  close: () => void
  clearFilters: () => void
  visible: boolean
}

const { RangePicker } = DatePicker;

const TableRangeFilter: FC<Props> = ({
  confirm,
  close,
  setSelectedKeys,
  clearFilters,
  selectedKeys,
  visible,
}) => {
  const PROJ_TZ = getProjectTimezone();
  const extractSelectedKey = (selectedKeys?.length && selectedKeys[0]) || null;
  const splitValue = (extractSelectedKey as string)?.split(',') || [];
  const value = splitValue.map(
    val => val ? dayjs(convertTimezoneTimeToLocalizedTime(val, PROJ_TZ)) : null,
  ) as [Dayjs, Dayjs];
  useEffect(() => {
    if (!visible) {
      confirm();
    }
  }, [visible]);


  const onRangeChange = (dates) => {
    if (dates && dates[0] && dates[1]) {
      const formattedDates = [
        toProjectDate(dates[0]).startOf('day').toDate().toISOString(),
        toProjectDate(dates[1]).endOf('day').toDate().toISOString(),
      ].join(',');
      setSelectedKeys([formattedDates]);
    } else {
      setSelectedKeys([]);
    }
  };
  return (
    <div>
        <div style={{ padding: '7px 8px' }}>
          <RangePicker
            presets={getRangePresets()}
            format="DD/MM/YYYY"
            allowClear={false}
            allowEmpty={[true, true]}
            value={value}
            onChange={onRangeChange}
            placement={'bottomRight'}
            disabledDate={current => disabledDate(current, 'past')}
          />
        </div>
        <Row
          wrap={false}
          align={'middle'}
          className={styles.achieveAtFilterFooter}
        >
          <Col flex="auto">
            <Button
              type="link"
              size="small"
              onClick={() => {
                clearFilters();
                setSelectedKeys([]);
                confirm();
                close();
              }}
              disabled={!!!selectedKeys?.length}
            >
              Reset
            </Button>
          </Col>
          <Col flex="none">
            <Button
              type="primary"
              size="small"
              onClick={() => {
                confirm();
                close();
              }}
            >
              Ok
            </Button>
          </Col>
        </Row>
    </div>
  );
};
export default TableRangeFilter;
