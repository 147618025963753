import { Bootstrap } from '@ynomia/core/dist/blueprint';
import { PROJECT_ID_STORAGE_KEY } from '../config/constants';
import client from './Client';

export async function getBootstrap(newProjectId?: string): Promise<Bootstrap> {
  const project = newProjectId || localStorage.getItem(PROJECT_ID_STORAGE_KEY) || undefined;
  const bootstrap = await client.server.bootstrap(
    project,
    { layers: false, ids: false },
  );
  localStorage.setItem(PROJECT_ID_STORAGE_KEY, bootstrap.project.metadata.id);
  return bootstrap;
}
