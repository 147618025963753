import {
  Layer,
  LayerChildren,
  LayerChildrenMap,
} from '../config/types/layers';

import { SiteTemplate } from '@ynomia/core/dist/blueprint';
import minBy from 'lodash/minBy';
import { sortCollectionBy } from '@ynomia/core/dist/utils';

export default class LayerSegmentedPickerHelper {
  /**
   * Generates the default value for the `segmented_picklist`
   * entry in dynamic form.
   * @param layersKeyedById redux layers selector.
   * @param layerId that you want to be selected by default.
   * @returns default value object.
   */
  static segmentedDestinationDefaultValue(
    layersKeyedById: Map<string, Layer>,
    layerId?: string,
  ): { [type: string]: string } | undefined {
    if (!layerId) return undefined;
    const layer = layersKeyedById.get(layerId);
    if (!layer) return undefined;
    return {
      [layer.type]: layer.id,
      ...this.segmentedDestinationDefaultValue(layersKeyedById, layer.parentID),
    };
  }

  /**
   * This is used to extract the chosen layer from the
   * `segmented_picklist` dynamic form entry.
   * @param layerChildrenMap redux layers selector.
   * @param segmentedPickerResult is the the output value from dynamic form.
   * @returns Layer ID.
   */
  static getYoungestLayer(
    layerChildrenMap: LayerChildrenMap,
    segmentedPickerResult: { [layerType: string]: string },
  ): string | undefined {
    return minBy(Object.values(segmentedPickerResult), layerId => (layerChildrenMap[layerId]
      ? layerChildrenMap[layerId].length
      : Infinity
    ));
  }

  /**
   * For use as a selector to create segemented picker columns.
   * This utilizes siteTemplates to determine if a site should be shown
   * and will list all children until the primary layer.
   * @param layersKeyedById redux layers selector.
   * @param siteTemplates redux clientCache state.
   * @param rootLayers redux layers selector.
   * @param layerChildrenMap redux layers selector.
   * @returns columns data for `segmented_picklist`.
   */
  static getLayerSegmentedPickerColumns(
    layersKeyedById:  Map<string, Layer>,
    siteTemplates: Array<SiteTemplate> | undefined,
    rootLayers: Array<Layer>,
    layerChildrenMap: LayerChildrenMap,
  ) {
    const columns: Array<any> = [];

    // Handle legacy projects with no site template
    if (!siteTemplates || siteTemplates.length === 0) {
      const primaryLayers = [...layersKeyedById.values()]
        .filter(layer => layer.isPrimary);
      const options = primaryLayers
        .map(layer => ({
          label: layersKeyedById.get(layer.id)?.label,
          value: layer.id,
        }));
      columns.push({
        id: primaryLayers[0]?.type,
        options,
      });
      return columns;
    }

    // Normal projects with site template
    const rootType = rootLayers[0]?.type;
    const rootLayersWithDestination = sortCollectionBy(
      rootLayers.filter(
        (layer:Layer) => {
          const { template } = layer;
          const siteTemplate = siteTemplates.find(({ id }) => id === template);
          return siteTemplate?.destination;
        },
      ),
      ['order'],
    );

    // Recursive function for adding the columns
    const addChildrenColumns = (
      layerChildren: Array<LayerChildren>,
      filters?: { [columnId: string]: string },
    ) => {
      if (layerChildren.length < 1) {
        return;
      }
      // Extract properties that should be common for the children
      const firstLayer = layersKeyedById.get(layerChildren[0].layerId)!;
      const { isPrimary, type } = firstLayer;

      // List the children as a column
      const options = layerChildren
        .sort(children => layersKeyedById.get(children.layerId)!.order)
        .map(children => ({
          label: layersKeyedById.get(children.layerId)?.label,
          value: children.layerId,
          filters,
        }));

      // If the column type exists, add to it, or create a new one
      const existingColumn = columns.find(({ id }) => id === type);
      if (existingColumn) {
        existingColumn.options = [...existingColumn.options, ...options];
      } else {
        columns.push({
          id: type,
          options,
        });
      }

      // Do not go further than primary layers
      if (!isPrimary) {
        layerChildren.forEach(layerChild => addChildrenColumns(
          layerChild.children,
          { ...filters, [type]: layerChild.layerId },
        ));
      }
    };

    // Start adding the columns!
    const multipleRootLayersWithDestination = rootLayersWithDestination.length > 1;
    // Show root layers if there are more than one
    if (multipleRootLayersWithDestination) {
      columns.push({
        id: rootType,
        options: rootLayersWithDestination
          .map(rootLayer => ({
            label: rootLayer.label,
            value: rootLayer.id,
          })),
      });
    }
    // Init recursive function to add children layers
    rootLayersWithDestination
      .forEach(rootLayer => addChildrenColumns(
        layerChildrenMap[rootLayer.id],
        multipleRootLayersWithDestination ? { [rootType]: rootLayer.id } : {},
      ));

    return columns;
  }
}
