
import { Navigate, useSearchParams } from 'react-router-dom';
import React, { FC } from 'react';
import { REDIRECT_TO } from '../../../config/constants';
import { getDefaultRoute } from '../../../services';

interface Props {
  isLoading?: boolean
}

const RootRoute: FC<Props> = ({ isLoading }) => {
  const [searchParams] = useSearchParams();

  const requestedRoute = searchParams.get(REDIRECT_TO) || undefined;
  if (isLoading) {
    return <></>;
  }

  const result = getDefaultRoute(requestedRoute);
  return <Navigate to={result} replace />;
};
export default RootRoute;
