
import {
  getAssetTypes,
  getAssetsKeyedByTypes,
  getCurrentFeature,
  getSelectedAssetType,
} from '../../../selectors';
import {
  removeFilterTypeChange,
  setTypeFilter,
} from '../../../actions';
import { DropdownFilter } from '../../atoms';
import { MenuProps } from 'antd';
import React from 'react';
import { analytics } from '../../../services';
import { getContextStores } from '../../../context';

export interface Props {
  size?: 'small' | 'default';
}

const Filters = ({ size = 'default' }: Props) => {
  /* Context */
  const contextStores = getContextStores();
  const {
    filterDispatch,
  } = contextStores;

  /* Selectors */
  const currentFeature = getCurrentFeature(contextStores);
  const assetTypes = getAssetTypes(contextStores);
  const assetsKeyedByTypes = getAssetsKeyedByTypes(contextStores);

  const items = assetTypes.map(({ label_pluralized, id }) => {
    const labelDisplay = `${label_pluralized} (${assetsKeyedByTypes.get(id)?.length || 0})`;
    return {
      label: labelDisplay,
      key: id,
    };
  });

  const onClick: MenuProps['onClick'] = ({ key }) => {
    analytics.trackEvent('Asset Type Dropdown Changed', { asset_type: key });
    setTypeFilter(key, currentFeature, filterDispatch);
    removeFilterTypeChange(currentFeature, filterDispatch);
  };

  // Auto select the first type if nothing is selected
  const currentType = getSelectedAssetType(contextStores);
  const { id } = currentType;
  const currentTypeLabel = (
    `${currentType?.label_pluralized} (${assetsKeyedByTypes.get(id)?.length || 0})`
  );

  return (
    <DropdownFilter
      label={currentTypeLabel}
      menu={{ onClick, items }}
      size={size}
    />
  );
};

export default Filters;