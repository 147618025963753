
import {
  Button,
  Divider,
  Input,
  InputNumber,
  Select,
  Switch,
} from 'antd';
import { DateTime, SegmentedPicklist } from '../';
import DynamicForm, {
  FormButton,
  FormEntry,
  FormValues,
} from '@ynomia/dynamic-form';
import { Field, FormikProps } from 'formik';
import React, { memo } from 'react';
import {
  convertLocalizedTimeToTimezoneTime,
  convertTimezoneTimeToLocalizedTime,
} from '../../../utils';
import { InfoCircleOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import styles from './styles.module.less';

export interface Props {
  fields: Array<FormEntry>;
  defaultValues?: FormValues;
  submitButtonText?: string;
  hideSubmitButton?: boolean;
  hideCancelButton?: boolean;
  disableSubmitButton?: boolean;
  isDisabled?: boolean;
  submitButtonLoading?: boolean;
  filtrexProps?: { [key: string]: any };
  onCancel?: () => void
  onSubmit?: (formValues: FormValues) => void | Promise<void>;
  onValueChange?: (
    fieldName: string,
    value: any,
    formikProps: FormikProps<FormValues>,
  ) => void | Promise<void>;
  dynamicFormRef?: (ref: DynamicForm | null) => void;
  extraFiltrexFunctions?: { [x: string]: (str?: string) => any };
}

/**
 * A styled implementation of Ynomia's "Dynamic Form" library, designed to be presented and
 * reused across numerous modal screens throughout the app.
 * @param {Props} props
 * @param {Array<FormEntry>} props.fields
 * @param {FormValues?} props.defaultValues
 * @param {string?} props.submitButtonText: Changes the text of the bottom fixed action button.
 * Defaults to "Submit".
 * @param {boolean?} props.hideSubmitButton: Do not show the option to submit the form.
 * @param {boolean?} props.hideCancelButton: Do not show the option to cancel the form.
 * @param {boolean?} props.disableSubmitButton: Disable just the submit button.
 * @param {boolean?} props.isDisabled: If `true`, the entire form will appear disabled,
 * including buttons.
 * @param {boolean?} props.submitButtonLoading: If `true`, the submit button will
 * show a loading indicator.
 * @param {any} props.filtrexProps: Provide additional properties that can be used in filtrex
 * conditionals for this form instance. See the Dynamic Form library docs for more information.
 * @param {any} props.onCancel
 * @param {any} props.onSubmit
 * @param {Function?} props.onValueChange: Callback function for each time a form field changes
 * as a result of a user's action (eg typing, new selection etc).
 */
const ModalForm = ({
  fields,
  defaultValues,
  onSubmit,
  submitButtonText,
  onCancel,
  hideSubmitButton,
  hideCancelButton,
  submitButtonLoading = false,
  isDisabled = false,
  onValueChange,
  filtrexProps,
  dynamicFormRef,
  extraFiltrexFunctions,
  disableSubmitButton = false,
}: Props) => {
  const handleRef = (ref: DynamicForm | null) => {
    dynamicFormRef?.(ref);
  };

  const formButtons: Array<FormButton> = [];

  if (!hideSubmitButton) formButtons.push({
    type: 'SUBMIT',
    style: 'default',
    text: submitButtonText,
    id: 'submit',
  });

  if (!hideCancelButton) formButtons.push({
    type: 'CANCEL',
    style: 'default',
    text: 'Cancel',
    id: 'cancel',
  });

  const generateButtons = (buttons: Array<FormButton>, formikProps: FormikProps<FormValues>) => (
    <div className={styles.buttonContainer}>
      {
        buttons.map((button) => {
          if (button.type === 'SUBMIT') {
            return (
                <Button
                  key={button.id}
                  style={{ marginLeft: 8 }}
                  onClick={() => formikProps.handleSubmit()}
                  type="primary"
                  loading={submitButtonLoading}
                  disabled={
                    (formikProps.submitCount > 0 && !formikProps.isValid)
                    || isDisabled
                    || disableSubmitButton
                  }
                >
                  {`${button.text || 'Submit'}`}
                </Button>
            );
          }

          if (button.type === 'CANCEL') {
            return (
              <Button
                key={button.id}
                onClick={onCancel}
                disabled={submitButtonLoading}
                type="default"
              >
                {`${button.text || 'Cancel'}`}
              </Button>
            );
          }
          return <div key={button.id}>{button.customButton}</div>;
        })
      }
    </div>
  );

  const handleSubmit = (formValues: FormValues) => {
    const processedFormValues = Object.entries(formValues || {})
      .filter(([, value]) => value !== undefined)
      .reduce((all, [fieldId, value]) => ({
        ...all,
        [fieldId]: value,
      }), {});

    if (onSubmit) {
      onSubmit(processedFormValues);
    }
  };

  const renderFieldHeader = (settings, entryShouldBeValidated, form, field) => {
    return (
      <div key={field.id} className={styles.formTitle}>
        <span>{settings.label}</span>
        <span className={styles.asteriskText}>{settings.isRequired === true && '*'}</span>
        {(entryShouldBeValidated && form.touched[field.name]) &&
          <span className={styles.requiredText}>{form.errors[field.name]}</span>
        }
      </div>
    );
  };

  type EntryComponentProps = {
    settings: FormEntry;
    formikProps: FormikProps<FormValues>;
    disabled: boolean;
    entryShouldBeValidated: boolean;
  };

  type FormikFieldData = {
    field: {
      name: string;
      value: any;
      onChange: () => void | Promise<void>;
      onBlur: () => void | Promise<void>;
    };
    form: {
      touched: { [field: string]: boolean };
      errors: { [field: string]: string };
    };
  };

  const formikValueChange = (
    formikProps: FormikProps<FormValues>,
    fieldName: string,
    value: any,
  ) => {
    formikProps.setFieldTouched(fieldName, true);
    formikProps.handleChange(fieldName)(value);
    onValueChange?.(fieldName, value, formikProps);
  };

  const disableForm = submitButtonLoading || isDisabled;

  const entryComponents = {
    divider: ({ settings }) => <Divider key={settings.id}/>,
    text: ({
      settings,
      formikProps,
      disabled,
      entryShouldBeValidated,
    }: EntryComponentProps) => (
      <Field name={settings.id} key={settings.id}>
        {({ field, form }: FormikFieldData) =>
          <>
            {renderFieldHeader(settings, entryShouldBeValidated, form, field)}
            <Input
              placeholder={settings.properties?.placeholder}
              value={field.value}
              onChange={value => formikValueChange(formikProps, field.name, value)}
              disabled={disabled || disableForm}
            />
          </>
        }
      </Field>
    ),
    textarea: ({
      settings,
      formikProps,
      disabled,
      entryShouldBeValidated,
    }: EntryComponentProps) => (
      <Field name={settings.id} key={settings.id}>
        {({ field, form }: FormikFieldData) => (
          <>
            {renderFieldHeader(settings, entryShouldBeValidated, form, field)}
            <TextArea
              rows={settings.properties?.minHeight || 4}
              placeholder={settings.properties?.placeholder}
              value={field.value}
              onChange={value => formikValueChange(formikProps, field.name, value)}
              maxLength={6}
              disabled={disabled || disableForm}
            />
          </>
        )}
      </Field>
    ),
    number: ({
      settings,
      formikProps,
      disabled,
      entryShouldBeValidated,
    }: EntryComponentProps) => (
      <Field name={settings.id} key={settings.id}>
        {({ field, form }: FormikFieldData) =>
          <>
            {renderFieldHeader(settings, entryShouldBeValidated, form, field)}
            <InputNumber
              key={settings.id}
              min={0}
              value={field.value}
              onChange={value => formikValueChange(formikProps, field.name, value)}
              placeholder={settings.properties?.placeholder}
              disabled={disabled || disableForm}
            />
          </>
        }
      </Field>
    ),
    picklist: ({
      settings,
      formikProps,
      disabled,
      entryShouldBeValidated,
    }: EntryComponentProps) => (
      <Field name={settings.id} key={settings.id}>
        {({ field, form }: FormikFieldData) => {
          const { tags, multi, maxCount } = settings.properties || {};

          const getPicklistMode = () => {
            if (tags) return 'tags';
            if (multi) return 'multiple';
            return undefined;
          };

          const getPicklistDefaultValue = () => {
            if (!field.value) return undefined;
            if (tags || multi) {
              return field.value.split(',');
            }
            return field.value;
          };

          return <>
            {renderFieldHeader(settings, entryShouldBeValidated, form, field)}
            <Select
              key={settings.id}
              allowClear={!settings.isRequired}
              mode={getPicklistMode()}
              defaultValue={getPicklistDefaultValue()}
              disabled={disabled || disableForm}
              placeholder="Please select"
              options={settings.properties?.options}
              onChange={(value) => {
                if (Array.isArray(value)) {
                  formikValueChange(formikProps, field.name, value.join(','));
                } else {
                  formikValueChange(formikProps, field.name, value || '');
                }
              }}
              getPopupContainer={triggerNode => triggerNode.parentElement}
              style={{ width: '100%' }}
              maxCount={maxCount}
              dropdownStyle={{ position: 'fixed' }}
            />
          </>;
        }}
      </Field>
    ),
    segmented_picklist: ({
      settings,
      formikProps,
      disabled,
      entryShouldBeValidated,
    }: EntryComponentProps) => (
      <Field name={settings.id} key={settings.id}>
        {({ field, form }: FormikFieldData) => (
          <>
            {renderFieldHeader(settings, entryShouldBeValidated, form, field)}
            <SegmentedPicklist
              key={settings.id}
              columns={settings.properties?.columns}
              displayDelimiter={settings.properties?.displayDelimiter}
              value={field.value}
              onValueChange={value => formikValueChange(formikProps, field.name, value)}
              disabled={disabled || disableForm}
              resetValuesOnChange={settings.properties?.resetValuesOnChange}
            />
          </>
        )}
      </Field>
    ),
    checkbox: ({
      settings,
      formikProps,
      disabled,
      entryShouldBeValidated,
    }: EntryComponentProps) => (
      <Field name={settings.id} key={settings.id}>
        {({ field, form }: FormikFieldData) => (
          <>
            {renderFieldHeader(settings, entryShouldBeValidated, form, field)}
            <Switch
              key={settings.id}
              onChange={value => formikValueChange(formikProps, field.name, value ? '1' : '')}
              defaultChecked={field.value}
              disabled={disabled || disableForm}
            />
          </>
        )}
      </Field>
    ),
    date: ({
      settings,
      formikProps,
      disabled,
      entryShouldBeValidated,
    }: EntryComponentProps) => (
      <Field name={settings.id} key={settings.id}>
        {({ field, form }: FormikFieldData) => (
          <>
            {renderFieldHeader(settings, entryShouldBeValidated, form, field)}
            <DateTime
              key={settings.id}
              onChange={value => formikValueChange(formikProps, field.name, value)}
              disabled={disabled || disableForm}
              value={field.value}
              restriction={settings.properties?.restriction}
              allowClear={settings?.properties?.allowClear}
              mode='date'
            />
          </>
        )}
      </Field>
    ),
    time: ({
      settings,
      formikProps,
      disabled,
      entryShouldBeValidated,
    }: EntryComponentProps) => (
      <Field name={settings.id} key={settings.id}>
        {({ field, form }: FormikFieldData) => {
          const {
            timezone,
            errorMessage,
            restriction,
            allowClear,
          } = settings.properties || {};

          return (
            <>
              {renderFieldHeader(settings, entryShouldBeValidated, form, field)}
              <DateTime
                key={settings.id}
                onChange={value => formikValueChange(formikProps, field.name, value)}
                disabled={disabled || disableForm}
                value={field.value}
                allowClear={allowClear}
                restriction={restriction}
                mode='time'
              />
              {timezone && <div
                className={`
                  ${styles.timezoneContainer}
                  ${timezone.disabled && styles.timezoneInfoContainerDisabled}
                  ${errorMessage && styles.timezoneInfoContainerError}
                `}
              >
                <InfoCircleOutlined className={styles.timezoneInfoIcon}/>
                <span className={styles.timezoneInfoText}>{timezone}</span>
              </div>}
            </>
          );
        }}
      </Field>
    ),
    datetime: ({
      settings,
      formikProps,
      disabled,
      entryShouldBeValidated,
    }: EntryComponentProps) => (
      <Field name={settings.id} key={settings.id}>
        {({ field, form }: FormikFieldData) => {
          const {
            timezone,
            errorMessage,
            restriction,
            format,
            allowClear,
            split,
          } = settings.properties || {};
          return (
            <>
              {renderFieldHeader(settings, entryShouldBeValidated, form, field)}
              <DateTime
                key={settings.id}
                onChange={value => formikValueChange(formikProps, field.name, value)}
                disabled={disabled || disableForm}
                value={field.value}
                restriction={restriction}
                format={format}
                mode={split ? 'dateTimeSplit' : 'datetime'}
                allowClear={allowClear}
              />
              {timezone && <div
                className={`
                  ${styles.timezoneContainer}
                  ${timezone.disabled && styles.timezoneInfoContainerDisabled}
                  ${errorMessage && styles.timezoneInfoContainerError}
                `}
              >
                <InfoCircleOutlined className={styles.timezoneInfoIcon}/>
                <span className={styles.timezoneInfoText}>{timezone}</span>
              </div>}
            </>
          );
        }}
      </Field>
    ),
  };

  const numberTransformation = {
    in: (value?: number) => (typeof value === 'number' ? String(value) : ''),
    out: (value?: string) => (
      value !== undefined && !Number.isNaN(parseFloat(value))
        ? parseFloat(value)
        : undefined
    ),
  };

  const checkboxTransformation = {
    in: (value?: boolean) => (value ? '1' : ''),
    out: (value?: string) => value === '1',
  };

  const picklistTransformation = {
    in: (value?: Array<string> | string) => (
      Array.isArray(value) ? value.join(',') : (value || '')
    ),
    out: (value?: string, entrySchema?: FormEntry) => {
      if (entrySchema?.properties?.multi && !!value && value !== '') {
        return value.split(',');
      }
      if (!value || value === '') {
        return undefined;
      }
      return value;
    },
  };

  const segmentedPicklistTransformation = {
    in: (value?: { [columnId: string]: string }) => (
      !value ? '' : JSON.stringify(value)
    ),
    out: (value?: string) => {
      if (!value || value === '') {
        return undefined;
      }
      return JSON.parse(value);
    },
  };

  const timestampTransformation = {
    in: (value?: Date | string, entrySchema?: FormEntry) => {
      const processDateObject = (date: Date): string => {
        let dateStr: string = date.toISOString();

        // Ensures that previous time selections aren't copied with an old date.
        if (entrySchema?.entryComponent === 'time') {
          const today = new Date();

          if (date) {
            today.setHours(date.getHours());
            today.setMinutes(date.getMinutes());
            today.setSeconds(date.getSeconds());
            today.setMilliseconds(date.getMilliseconds());
          }

          dateStr = today.toISOString();
        }

        // Ensures that the timestamp has been converted to the correct local timestamp
        // (which reflects project time) so that the date picker shows an accurate value.
        const tz = entrySchema?.properties?.timezone;
        if (tz) {
          dateStr = convertTimezoneTimeToLocalizedTime(dateStr, tz).toISOString();
        }

        return dateStr;
      };

      if (!value) {
        return '';
      }
      if (typeof value === 'string') {
        const parsedDate = new Date(value);
        if (Number.isNaN(parsedDate.getTime())) {
          return '';
        }
        return processDateObject(parsedDate);
      }
      return processDateObject(value);
    },
    out: (value?: string, entrySchema?: FormEntry) => {
      if (!value || value === '') {
        return undefined;
      }
      // If this time picker has a specific timezone against it, we had to fake the timestamp
      // timestamp to appear correct to a user in the date picker - so we now need to convert
      // that back into the actual timezone they were choosing it for:
      const tz = entrySchema?.properties?.timezone;
      if (tz) {
        return convertLocalizedTimeToTimezoneTime(value, tz);
      }
      return new Date(value);
    },
  };

  const photoTransformation = {
    in: (value?: string | string[]) => {
      if (!value) {
        return '';
      }
      if (typeof value === 'string') {
        return value;
      }
      return value.join(',');
    },
    out: (value?: string, entrySchema?: FormEntry) => {
      if (!value || value === '') {
        return undefined;
      }
      if (entrySchema?.properties?.multi) {
        return value.split(',');
      }
      return [value];
    },
  };

  const entryTransformations = {
    number: numberTransformation,
    checkbox: checkboxTransformation,
    date: timestampTransformation,
    time: timestampTransformation,
    picklist: picklistTransformation,
    segmented_picklist: segmentedPicklistTransformation,
    datetime: timestampTransformation,
    photo: photoTransformation,
  };

  const formContentContainer = (formContent: any) => (
    <div className={styles.formContentContainer}>
      {formContent}
    </div>
  );

  return (
    <DynamicForm
      entries={fields as any}
      key={JSON.stringify(fields)}
      entryComponents={entryComponents}
      entryTransformers={entryTransformations}
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      buttons={formButtons}
      generateButtons={generateButtons}
      contentContainer={formContentContainer}
      validateOnMount
      filtrexProps={filtrexProps}
      ref={ref => handleRef(ref)}
      extraFiltrexFunctions={extraFiltrexFunctions}
    />
  );
};

export default memo(ModalForm);
