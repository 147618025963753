import { AssetType, Field } from '@ynomia/core/dist/blueprint';
import {
  getAssetTypes,
  getAssetsArray,
  getAssetsKeyedByTypes,
  getCurrentFeatureFilters,
} from '.';

import { DEFAULT_STATUS_COLOR } from '../config/constants';
import { createSelector } from 'reselect';

export const getSelectedAssetType = createSelector(
  [getCurrentFeatureFilters, getAssetTypes],
  (currentFeatureFilters, assetTypes) => {
    const { typeFilter } = currentFeatureFilters;
    return (assetTypes.find(({ type }) => type === typeFilter) || assetTypes[0]) as AssetType;
  },
);

export const getSuppliersByTypeFilters = createSelector(
  [getCurrentFeatureFilters, getAssetsKeyedByTypes],
  (currentFeatureFilters, assetsKeyedByTypes) => {
    const { typeFilter } = currentFeatureFilters;

    if (!typeFilter || !assetsKeyedByTypes) {
      return [];
    }
    const suppliersByTypeFilter = assetsKeyedByTypes.get(typeFilter);
    const supplierListFromAsset = new Map(
      suppliersByTypeFilter?.map(asset => [asset.fields?.supplier, asset]),
    );

    const supplierKeys = [...supplierListFromAsset.keys()];

    if (supplierKeys[0] === 'undefined' && supplierKeys.length === 1) {
      return [];
    }

    return supplierKeys as Array<string>;
  },
);

export const getSuppliersOptions = createSelector(
  [
    getAssetsArray,
    getSelectedAssetType,
  ],
  (
    assetsArray,
    selectedAssetType,
  ) => {
    const fields = selectedAssetType?.fields || [];
    let suppliersOptions:Array <{
      label: string,
      value: string,
    }>  = [];

    const supplierListFromAsset = new Map(
      assetsArray?.map(asset => [`${asset.fields?.supplier || ''}`, asset]),
    );

    const supplierField = fields?.find(field => field.id === 'supplier');
    if (supplierField) {
      suppliersOptions = supplierField?.properties?.options as Array<{
        label: string,
        value: string,
      }> || [];
    }

    const hasAssetWithoutSupplier = supplierListFromAsset.get('');
    if (hasAssetWithoutSupplier) {
      const blankOption = {
        label: !suppliersOptions.length &&  selectedAssetType ?
          selectedAssetType.name : '(blank)',
        value: '',
      };
      suppliersOptions = [...suppliersOptions, blankOption];
    }

    return suppliersOptions;
  },
);

export const getObservationTypes = createSelector(
  [getSelectedAssetType],
  (selectedAssetType) => {
    const observationTypes = selectedAssetType?.observations || [];
    return observationTypes;
  },
);

export const getSelectedAssetTypeStatuses = createSelector(
  [getSelectedAssetType],
  (selectedAssetType) => {
    const statuses = selectedAssetType?.statuses || [];
    return statuses;
  },
);

export const getFieldsFromObservationTypesKeyedById = createSelector(
  [getObservationTypes],
  (observationTypes) => {
    const fieldsFromObservationTypesKeyedById = new Map();
    observationTypes.forEach(({ id, fields }) => {
      const fieldsKeyedById = new Map(fields.map(field => [field.id, field]));
      fieldsFromObservationTypesKeyedById.set(id, fieldsKeyedById);
    });

    return fieldsFromObservationTypesKeyedById as Map<string, Map<string, Field>>;
  },
);

export const getColorScheme = createSelector(
  [getSelectedAssetType],
  (selectedAssetType) => {
    const assetStatuses = selectedAssetType?.statuses || [];
    return assetStatuses.reduce((acc, { label, color }) => ({
      ...acc,
      [label]: color || DEFAULT_STATUS_COLOR,
    }), {});
  },
);
