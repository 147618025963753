import { Card, Table } from 'antd';
import type { DraggableData, DraggableEvent } from 'react-draggable';
import React, { FC, useEffect, useRef, useState } from 'react';
import {
  getAssetStatusesKeyedById,
  getAssetsKeyedByTwinId,
  getSelectedAssetType,
  getTimeTravelAssetsById,
} from '../../../selectors';
import { Asset } from '../../../config/types';
import { AssetDetails } from '..';
import { AssetStatus } from '@ynomia/core/dist/blueprint';
import { CloseOutlined } from '@ant-design/icons';
import { ColorIndicator } from '../../atoms';
import { DEFAULT_STATUS_COLOR } from '../../../config/constants';
import Draggable from 'react-draggable';
import { ResizableBox } from 'react-resizable';
import type { ResizeCallbackData } from 'react-resizable';
import { TableRef } from 'antd/es/table';
import { formatDate } from '../../../utils';
import { getContextStores } from '../../../context';
import max from 'lodash/max';
import styles from './styles.module.less';


interface Props {
  showModal: boolean
  setShowModal: (boolean) => void
  assetTwinData: Array<string>
}

const TwinsTable: FC<Props> = ({ assetTwinData, showModal, setShowModal }) => {
  /* Context  */
  const contextStores = getContextStores();

  /* Selectors */
  const assetsKeyedByTwinId = getAssetsKeyedByTwinId(contextStores);
  const assetStatusesKeyedById = getAssetStatusesKeyedById(contextStores);
  const selectedAssetType = getSelectedAssetType(contextStores);
  const { slots, modelIdsSlotIdReference } = selectedAssetType || {};
  const slotsKeyById = new Map(slots?.map(slot => [slot.id, slot]));
  const timeTravelAssetsById = getTimeTravelAssetsById(contextStores);

  const assets = assetTwinData
    .map((name) => {
      const twinIDAsset = assetsKeyedByTwinId.get(name);
      if (!modelIdsSlotIdReference) return twinIDAsset;

      const currentSlot = slotsKeyById.get(modelIdsSlotIdReference)!;
      const relationship = currentSlot?.relationship;
      const assetSlots = twinIDAsset?.slots || {};
      const slot = assetSlots[relationship === 'has_many' ? 'parents' : 'children'];
      const assetIdsFromSlot = slot?.[currentSlot.foreign_slot_id];

      if (!assetIdsFromSlot) return undefined;

      if (Array.isArray(assetIdsFromSlot)) {
        return assetIdsFromSlot.map(id => timeTravelAssetsById.get(id));
      }

      return timeTravelAssetsById.get(assetIdsFromSlot);
    })
    .flat()
    .filter(asset => !!asset) as Array<Asset>;

  const assetData = assets
    .map((asset) => {
      const { label, id } = asset;
      const { status, statusUpdatedDate } = timeTravelAssetsById.get(id) || {};
      const assetStatus = (status && assetStatusesKeyedById.get(status)) || ({} as AssetStatus);
      return {
        label,
        status: assetStatus?.label || '',
        id,
        color: assetStatus?.color || DEFAULT_STATUS_COLOR,
        statusUpdatedDate: formatDate(statusUpdatedDate),
      };
    });

  const handleCancel = () => {
    setShowModal(false);
  };

  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const [interacting, setInteracting] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [height, setHeight] = useState<number>(100);
  const [width, setWidth] = useState(500);

  const tableRef = useRef<TableRef | null>(null);
  const draggleRef = useRef<HTMLDivElement>(null);

  const columns = [
    {
      title: 'Label',
      dataIndex: 'label',
      key: 'label',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',

      renderCell: (_, { status, color }) => (
        <div style={{ display: 'flex' }}>
          <div>
            <ColorIndicator color={color} isRowSelected={true} />
          </div>
          <div style={{ paddingLeft: 8 }}>{status}</div>
        </div>
      ),
    },
    {
      title: 'Achieved At',
      dataIndex: 'statusUpdatedDate',
      key: 'statusUpdatedDate',
    },
  ];

  const expandedRowRender = ({ id }) => <AssetDetails assetId={id} />;

  const onDragStart = (_event: DraggableEvent, uiData: DraggableData) => {
    const { innerHeight, innerWidth } = window;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: innerWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: innerHeight - (targetRect.bottom - uiData.y),
    });
  };

  const getTableHeight = () => {
    if (!tableRef?.current?.nativeElement?.scrollHeight) return 100;
    return tableRef?.current?.nativeElement?.clientHeight + 30;
  };

  const onResizeHeight = (size: number = 0) => {
    const largerHeight = max([
      getTableHeight(),
      size,
    ]) as number;
    setHeight(largerHeight);
  };

  const onResize = (event, { size }: ResizeCallbackData) => {
    onResizeHeight(size.height);
    setWidth(size.width);
  };

  useEffect(() => {
    setHeight(getTableHeight());
  }, [getTableHeight(), assetData]);

  return (
    <div className={styles.container} style={interacting ? { pointerEvents: 'auto' } : {}}>
      <div
        className={styles.draggableWrapper}
        style={{
          display: showModal ? 'block' : 'none',
        }}
      >
        <Draggable
          nodeRef={draggleRef}
          disabled={disabled}
          bounds={bounds}
          defaultPosition={{ x: 100, y: 100 }}
          onStart={(event, uiData) => {
            setInteracting(true);
            onDragStart(event, uiData);
          }}
          onStop={() => setInteracting(false)}
        >
          <div ref={draggleRef} className={styles.header}>
            <Card
              title={`Selected (${assetData.length})`}
              onMouseOver={() => {
                setDisabled(false);
              }}
              extra={
                <CloseOutlined onClick={handleCancel} style={{ cursor: 'pointer' }} />
              }
              styles={{ body: { padding: 0 } }}
            >
              <div
                style={{
                  width: width,
                  cursor: 'auto',
                }}
                onMouseOver={(e) => {
                  e.stopPropagation();
                  setDisabled(true);
                }}
              >
                <ResizableBox
                  width={width}
                  height={height}
                  onResize={onResize}
                  onResizeStart={() => setInteracting(true)}
                  onResizeStop={() => setInteracting(false)}
                  minConstraints={[500, getTableHeight()]}
                  maxConstraints={[10000, getTableHeight()]}
                  style={{ marginBottom: 20 }}
                >
                  <Table
                    showHeader={false}
                    columns={columns}
                    size="small"
                    dataSource={assetData.reverse()}
                    expandable={{
                      expandedRowRender,
                      defaultExpandedRowKeys: ['0'],
                      // We want to resize once the table has a chance to render
                      onExpand: () => setTimeout(onResizeHeight, 0),
                    }}
                    pagination={false}
                    rowKey="label"
                    ref={tableRef}
                    style={{ maxHeight: window.innerHeight * 0.7, overflow: 'auto' }}
                  />
                </ResizableBox>
              </div>
            </Card>
          </div>
        </Draggable>
      </div>
    </div>
  );
};

export default TwinsTable;
