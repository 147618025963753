import {
  ISO_DATE_FORMAT,
  checkAccessRulesFilteredByTableFilter,
  checkSearchAccessRulesFilter,
  formatDate,
  generateCSV,
} from '../utils';
import { Switch, Tag } from 'antd';
import { AccessRule } from '../config/types';
import { MinusOutlined } from '@ant-design/icons';
import React from 'react';
import { createSelector } from 'reselect';
import dayjs from 'dayjs';
import { getUserManagementFlags } from '.';
import map from './map';


/**
 * AccessRules are stored as a collection keyed by their ID. This method returns these as a list.
 */
export const getAccessRulesArray = createSelector(
  [map.accessRulesKeyedById],
  (accessRulesKeyedById) => {
    const keys = [...accessRulesKeyedById.keys()];
    if (!keys.length) {
      return [];
    }

    return keys.map(id => accessRulesKeyedById.get(id)) as Array<AccessRule>;
  });

/**
 * Returns AccessRules from the state collection as an  array filtered by searchAccessRules
 * and sorted by most recent createdBy.
 */
export const getAccessRuleFilteredArray = createSelector(
  [getAccessRulesArray, map.searchAccessRules, map.filtersAccessRulesTable],
  (accessRulesArray, searchAccessRules, filtersAccessRulesTable) => {
    const accessRulesSortedByCreatedBy = accessRulesArray.sort((a, b) =>
      new Date(a.createdAt).valueOf() - new Date(b.createdAt).valueOf(),
    );
    return accessRulesSortedByCreatedBy.filter(accessRule =>
      checkSearchAccessRulesFilter(accessRule, searchAccessRules)
      && checkAccessRulesFilteredByTableFilter(accessRule, filtersAccessRulesTable),
    ) as Array<AccessRule>;
  },
);

export const getAccessRulesColumns = createSelector(
  [getUserManagementFlags, map.visibilityFiltersKeyedById],
  (userManagementFlags, visibilityFiltersKeyedById) => {
    const enableUsers = userManagementFlags?.enable_users || false;
    const enableMfa = userManagementFlags?.enable_mfa || false;
    return [
      {
        title: 'Enabled',
        dataIndex: 'enabled',
        key: 'enabled',
        editable: enableUsers,
        fixed: 'left',
        sorter: (a, b) => Number(b.enabled) - Number(a.enabled),
        onFilter: () => true,
        filters: [
          {
            text: 'Enabled On',
            value: true,
          },
          {
            text: 'Enabled Off',
            value: false,
          },
        ],
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        editable: true,
        fixed: 'left',
        sorter: (a, b) => a.name.localeCompare(b.name),
      },
      {
        title: 'Email',
        dataIndex: 'email',
        editable: false,
        key: 'email',
        sorter: (a, b) => a.name.localeCompare(b.name),
      },
      {
        title: 'Account Type',
        dataIndex: 'sso',
        key: 'sso',
        editable: false,
        render: (sso) => {
          return sso ? <div>SSO</div> : <div>Email/Password</div>;
        },
        onFilter: () => true,
        sorter: (a, b) => Number(a.sso) - Number(b.sso),
        filters: [
          {
            text: 'SSO',
            value: true,
          },
          {
            text: 'Email/Password',
            value: false,
          },
        ],
      },
      {
        title: 'MFA?',
        dataIndex: 'mfa',
        key: 'mfa',
        editable: enableMfa,
        render: (mfa, record) => {
          const sso = record?.sso || false;
          return sso ?
            <div style={{ marginLeft: '15px' }}><MinusOutlined /></div>
            : <Switch disabled={enableMfa} checked={mfa} />;
        },
        sorter: (a, b) => Number(b.mfa) - Number(a.mfa),
        onFilter: () => true,
        filters: [
          {
            text: 'MFA On',
            value: true,
          },
          {
            text: 'MFA Off',
            value: false,
          },
        ],
      },
      {
        title: 'Role(s)',
        key: 'roles',
        dataIndex: 'roles',
        editable: true,
        width: 200,
        sorter: (a, b) => a.roles.toString().localeCompare(b.roles.toString()),
        render: (_, { roles }) => (
          <>
            {roles.map((tag) => {
              return (
                <Tag closable={false} key={tag}>
                  {tag}
                </Tag>
              );
            })}
          </>
        ),
      },
      {
        title: 'Filters',
        key: 'filters',
        dataIndex: 'filters',
        editable: true,
        sorter: (a, b) => {
          const [aId] = a.filters;
          const [bId] = b.filters;
          const aVisibilityFiltersLabel = visibilityFiltersKeyedById.get(aId)?.label || '';
          const bVisibilityFiltersLabel = visibilityFiltersKeyedById.get(bId)?.label || '';
          return aVisibilityFiltersLabel.localeCompare(bVisibilityFiltersLabel);
        },
        render: (_, { filters }) => {
          return (
            <>
              {filters.map((id) => {
                return (
                  <Tag closable={false} key={id}>
                    {visibilityFiltersKeyedById.get(id)?.label}
                  </Tag>
                );
              })}
            </>
          );
        },
      },
      {
        title: 'Added',
        dataIndex: 'createdAt',
        key: 'createdAt',
        editable: false,
        sorter: (a, b) => new Date(a.createdAt).valueOf() - new Date(b.createdAt).valueOf(),
        render: createdAt => (
          <span>
            {formatDate(createdAt)}
          </span>
        ),
      },
      {
        title: 'Updated',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        editable: false,
        sorter: (a, b) => new Date(a.createdAt).valueOf() - new Date(b.createdAt).valueOf(),
        render: createdAt => (
          <span>
            {formatDate(createdAt)}
          </span>
        ),
      },
      {
        title: 'Notes',
        dataIndex: 'notes',
        key: 'notes',
        editable: true,
        width: 300,
        sorter: (a, b) => {
          const aNotes = a?.notes || '';
          const bNotes = b?.notes || '';
          return aNotes.localeCompare(bNotes);
        },
      },
    ];
  },
);

export const getAccessRulesCSV = createSelector(
  [
    getAccessRulesColumns,
    getAccessRuleFilteredArray,
  ],  (
    accessRulesColumns,
    accessRuleFilteredArray,
  ) => {
    const headerTitles = accessRulesColumns.map(column => column.title);
    const headerKeys = accessRulesColumns.map(column => column.dataIndex);

    const statusSummaryCSV = accessRuleFilteredArray.map((accessRule) => {
      const flattenAccessRule = {
        ...accessRule,
        email: accessRule.user.email,
        sso: accessRule.user.sso,
      };


      return headerKeys.map((dataIndex) => {

        if (dataIndex === 'filters') {
          return flattenAccessRule[dataIndex].map(({ label }) => label).toString();
        } else if (dataIndex === 'sso') {
          return flattenAccessRule[dataIndex] ? 'SSO' : 'Email/Password';
        } else if (dataIndex === 'mfa') {
          const sso = flattenAccessRule.sso || false;
          return sso ? '-' : flattenAccessRule[dataIndex].toString();
        } else if (typeof flattenAccessRule[dataIndex] === 'boolean') {
          return flattenAccessRule[dataIndex] ? 'true' : 'false';
        } else if (Array.isArray(flattenAccessRule[dataIndex])) {
          return flattenAccessRule[dataIndex].toString();
        } else if (
          flattenAccessRule[dataIndex]
          && dayjs(flattenAccessRule[dataIndex], ISO_DATE_FORMAT).isValid()
        ) {
          return formatDate(flattenAccessRule[dataIndex]);
        } else {
          return `${flattenAccessRule[dataIndex] || ''}`;
        }


      });
    });

    return generateCSV(headerTitles, statusSummaryCSV);
  },
);
