let exportFn;

type Options = {
  currency: string | undefined,
  currencyFormatCallback?: (arg0: string, arg1: Options) => {},
  tooltipOffset: {
    x: number,
    y: number,
  },
  anchorToPoint: boolean,
  appendToBody: boolean,
  class: string | undefined,
  pointClass: string,
};

const hasClass = (element: HTMLElement, className: string): boolean =>{
  return (' ' + element.getAttribute('class') + ' ').indexOf(' ' + className + ' ') > -1;
};

const showTooltip = (element: HTMLElement): boolean => {
  if (!hasClass(element, 'tooltip-show')) {
    element.className = `${element.className} tooltip-show tooltip-show-animation`;
    return true;
  }
  return false;
};

const hideTooltip = (element: HTMLElement): boolean => {
  const regex = new RegExp('tooltip-show tooltip-show-animation' + '\\s*', 'gi');
  element.className = element.className.replace(regex, '').trim();
  return false;
};

const getTooltipElement = (options: Options, $chart: HTMLElement): HTMLElement => {
  const {
    appendToBody,
  } = options;
  const optionsCssClass = options.class;

  let $toolTip = appendToBody ?
    document.querySelector('.chartist-tooltip') : $chart.querySelector('.chartist-tooltip');

  if (!$toolTip) {
    $toolTip = document.createElement('div');
    $toolTip.className = optionsCssClass ?
      `chartist-tooltip ${optionsCssClass}` : 'chartist-tooltip';
    if (!options.appendToBody) {
      $chart.appendChild($toolTip);
    } else {
      document.body.appendChild($toolTip);
    }
  }

  return $toolTip as HTMLElement;
};

const getToolTipStyle = (event, $chart: HTMLElement, $tooltipOffsetParent, options: Options) => {
  const box = $chart.getBoundingClientRect();
  const offsetBox = $tooltipOffsetParent.getBoundingClientRect();
  const { tooltipOffset } = options;
  const { x, y } = tooltipOffset;

  const $toolTip = getTooltipElement(options, $chart);

  let height = $toolTip.offsetHeight;
  let width = $toolTip.offsetWidth;

  const { target } = event;
  const { x2, y2 } = target;

  const offsetX = -width / 2 + x;
  const offsetY = -height + y;

  return {
    leftValue: x2?.baseVal.value + box.left + pageXOffset,
    topValue: y2?.baseVal.value + box.top + pageYOffset,
    allOffsetLeft: -offsetBox.left - pageXOffset + offsetX,
    allOffsetTop: -offsetBox.top - pageYOffset + offsetY,
  };
};

const getAnchor = (anchorToPoint, event) => {
  const { target } = event;
  const { x2, y2 } = target;
  return anchorToPoint === true && x2 && y2;
};


const setPosition = (
  $chart: HTMLElement,
  $toolTip,
  options: Options,
  $tooltipOffsetParent,
  event,
) => {
  const { anchorToPoint, appendToBody } = options;
  const { offsetHeight, offsetWidth } = $toolTip.offsetHeight;

  const { x, y } = options.tooltipOffset;
  const { pageX, pageY } = event;

  const offsetX = -offsetWidth / 2 + x;
  const offsetY = -offsetHeight + y;

  const anchor = getAnchor(anchorToPoint, event);

  const {
    leftValue,
    topValue,
    allOffsetLeft,
    allOffsetTop,
  } = getToolTipStyle(event, $chart, $tooltipOffsetParent, options);

  if (appendToBody) {
    if (anchor) {
      $toolTip.style.left = leftValue + offsetX + 'px';
      $toolTip.style.top = topValue + offsetY + 'px';
    } else {
      $toolTip.style.left = pageX + offsetX + 'px';
      $toolTip.style.top = pageY + offsetY + 'px';
    }
  } else {
    if (anchor) {
      $toolTip.style.left = leftValue + allOffsetLeft + 'px';
      $toolTip.style.top = topValue + allOffsetTop + 'px';
    } else {
      $toolTip.style.left = pageX + allOffsetLeft + 'px';
      $toolTip.style.top = pageY + allOffsetTop + 'px';
    }
  }

  return $toolTip;
};

const getTooltipText = (options, event): string | null => {
  const {
    transformTooltipTextFnc,
    tooltipFnc, metaIsHTML,
    currency,
    currencyFormatCallback,
  } = options;
  const $point = event.target;
  let meta = $point.getAttribute('ct:meta') || '';
  let tooltipText = '';
  let value = $point.getAttribute('ct:value');

  if (!value) {
    return null;
  }
  if (transformTooltipTextFnc && typeof transformTooltipTextFnc === 'function') {
    value = transformTooltipTextFnc(value);
  }
  if (tooltipFnc && typeof tooltipFnc === 'function') {
    tooltipText = tooltipFnc(meta, value);
  } else {
    if (metaIsHTML) {
      const txt = document.createElement('textarea');
      txt.innerHTML = meta;
      meta = txt.value;
    }

    meta = '<span class="chartist-tooltip-meta">' + meta + '</span>';

    if (!!meta) {
      tooltipText += meta + '<br>';
    }

    if (value) {
      if (currency) {
        if (currencyFormatCallback != undefined) {
          value = currencyFormatCallback(value, options);
        } else {
          value = currency + value.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,');
        }
      }
      value = '<span class="chartist-tooltip-value">' + value + '</span>';
      tooltipText += value;
    }
  }

  return tooltipText;
};

const on = ($chart, event, callback) => {
  $chart.addEventListener(event, callback);
};

((root: Window & typeof globalThis | undefined, factory) => {
  if (root === undefined && window !== undefined) root = window;
  if (!root) { return; }
  exportFn = factory((root as any).Chartist);
  root['Chartist.plugins.tooltip'] = exportFn;

})(this, (Chartist) => {
  /**
   * Chartist.js plugin to display a data label on top of the points in a line chart.
   *
   */
  ((window, document) => {
    const defaultOptions: Options = {
      currency: undefined,
      tooltipOffset: {
        x: 0,
        y: -20,
      },
      anchorToPoint: false,
      appendToBody: true,
      class: undefined,
      pointClass: 'ct-point',
    };

    /**
     * Returns the first positioned parent of the element
     * @return HTMLElement
     */
    const offsetParent = (elem) => {
      let parent;
      if (elem.offsetParent) {
        // Using the native property if possible
        parent = elem.offsetParent;
        if (!parent) {
          parent = document.body.parentElement;
        }
        return parent;
      }

      parent = elem.parentNode;
      if (!parent) {
        return document.body.parentElement;
      }

      if (window.getComputedStyle(parent).position !== 'static') {
        return parent;
      } else if (parent.tagName === 'BODY') {
        return parent.parentElement;
      } else {
        return offsetParent(parent);
      }
    };

    Chartist.plugins = Chartist.plugins || {};
    Chartist.plugins.tooltip = (options) => {
      options = Chartist.extend({}, defaultOptions, options);
      const {
        anchorToPoint,
        appendToBody,
      } = options;

      const tooltip = (chart) => {
        const $chart = chart.container;
        let $toolTipIsShown = false;
        let $tooltipOffsetParent = offsetParent($chart);
        const $toolTip = getTooltipElement(options, $chart);

        $toolTipIsShown = hideTooltip($toolTip);

        on($chart, 'mouseover', (event) => {

          const tooltipText = getTooltipText(options, event);

          if (!tooltipText) {
            $toolTipIsShown = hideTooltip($toolTip);
          } else {
            $toolTip.innerHTML = tooltipText;
          }

          if (appendToBody !== true) {
            $tooltipOffsetParent = offsetParent($chart);
          }
          if ($toolTip.style.display !== 'absolute') {
            $toolTip.style.display = 'absolute';
          }
          setPosition($chart, $toolTip, options, $tooltipOffsetParent, event);
          $toolTipIsShown = showTooltip($toolTip);
        });

        on($chart, 'mouseout', () => {
          $toolTipIsShown = hideTooltip($toolTip);
        });

        on($chart, 'mousemove', (event) => {
          const tooltipText = getTooltipText(options, event);
          if (!tooltipText) {
            $toolTipIsShown = hideTooltip($toolTip);
          }

          if (!anchorToPoint && $toolTipIsShown) {
            setPosition($chart, $toolTip, options, $tooltipOffsetParent, event);
          }
        });

      };
      return tooltip;
    };

  })(window, document);

  return Chartist.plugins.tooltip;
});

export default exportFn;