import React, { FC } from 'react';
import styles from './styles.module.less';

interface Props {
  collapsed: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement> | undefined;
}

const CollapseButton: FC<Props> = ({ collapsed, onClick }) => {
  return (
    <div
      className={styles.menuLeftToggle}
      onClick={onClick}
      style={collapsed ? { transform: 'rotate(180deg)' } : {}}
    >
      <span></span>
      <span></span>
    </div>
  );
};
export default CollapseButton;
