import {
  getAssetsArray,
  getAssetsKeyedByLayers,
  getFilteredAssetsKeyedByStatusForDigitalTwin,
  getPrimaryLayers,
  getSelectedAssetType,
} from '.';
import { createSelector } from 'reselect';
import map from './map';

export const getTwinIdsKeyedByAssetId = createSelector(
  [map.assetsKeyedById, getSelectedAssetType, getAssetsArray],
  (assetsKeyedById, selectedAssetType, assetArray) => {
    const { slots, modelIdsSlotIdReference } = selectedAssetType || {};

    if (modelIdsSlotIdReference) {
      const slotsKeyById = new Map(slots?.map(slot => [slot.id, slot]));
      const currentSlot = slotsKeyById.get(modelIdsSlotIdReference);
      const relationship = currentSlot?.relationship;

      const twinArray = assetArray
        .map((asset) => {
          const { id } = asset;
          const assetSlots =  asset?.slots || {};
          const slot =  assetSlots[relationship === 'has_many' ? 'children' : 'parents'];
          const assetIdsFromSlot = slot?.[modelIdsSlotIdReference];
          const twinFromSlot = typeof assetIdsFromSlot === 'string'
            ? [assetIdsFromSlot]
            : assetIdsFromSlot
              ?.map(assetId => assetsKeyedById.get(assetId)?.destination?.twinID || [])
              ?.flat();
          return { id, twinID: twinFromSlot };
        })
        .filter(({ twinID }) => twinID);

      return new Map(twinArray.map(twin => [twin.id, twin]));
    }

    const twinArray = assetArray
      .map((asset) => {
        const { destination, id } = asset;
        const twinID = destination?.twinID;
        return { id, twinID };
      })
      .filter(({ twinID }) => twinID);
    return new Map(twinArray.map(twin => [twin.id, twin]));
  },
);

export const getLayersTwinFormat = createSelector(
  [
    getPrimaryLayers,
    getAssetsKeyedByLayers,
    getSelectedAssetType,
    getFilteredAssetsKeyedByStatusForDigitalTwin,
    getTwinIdsKeyedByAssetId,
  ],
  (
    primaryLayers,
    assetsKeyedByLayers,
    selectedAssetType,
    filteredAssetsKeyedByStatus,
    twinIdsKeyedByAssetId,
  ) => {
    if (!primaryLayers || !assetsKeyedByLayers || !filteredAssetsKeyedByStatus) {
      return [];
    }
    const { statuses } = selectedAssetType;
    const statusIndex = {};
    statuses?.forEach(({ id, label }) => {
      const assets = filteredAssetsKeyedByStatus.get(id);
      statusIndex[label] = {
        name: label,
        assets: assets
          ?.map((asset) => {
            return twinIdsKeyedByAssetId.get(asset.id!)?.twinID || [];
          })
          ?.flat(),
        count: assets?.length,
      };
    });

    const indexedByLevel = {};
    primaryLayers.forEach(({ id, label }) => {
      const assets = assetsKeyedByLayers.get(id);
      indexedByLevel[label] = {
        name: label,
        count: assets?.length,
        typeIndex: {},
        assets: assets
          ?.map((asset) => {
            return twinIdsKeyedByAssetId.get(asset.id) || [];
          })
          ?.flat(),
        statusIndex,
      };
    });

    return indexedByLevel;
  },
);

export const getTwinData = createSelector(
  [getLayersTwinFormat, map.lastFetchStartTime],
  (layersTwinFormat, lastFetchStartTime) => {
    return {
      indexedByLevel: layersTwinFormat,
      lastFetched: lastFetchStartTime,
    };
  },
);
