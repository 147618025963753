import { Asset, Layer } from '../config/types';

export const orderLayers = (layers: Array<Layer>): Array<Layer> => {
  const newlayer = layers?.sort((a, b) => {
    if (b.order === a.order) {
      return String(a.label).localeCompare(String(b.label));
    }
    return b.order - a.order;
  });
  return newlayer;
};

export const getFirstLayerWithLayerType = (
  layersKeyedById: Map<string, Layer>,
  layerType: string,
  id?: string | null,
) => {
  if (!id) return null;
  const currentLayer: Layer = layersKeyedById.get(id)!;
  if (!currentLayer) return null;
  if (currentLayer.type === layerType) return currentLayer;
  return getFirstLayerWithLayerType(layersKeyedById, layerType, currentLayer.parentID);
};

export const getLayer = (
  layersKeyedById: Map<string, Layer>,
  layerID: string | null,
  layerType,
) => {
  const layer = getFirstLayerWithLayerType(layersKeyedById, layerType, layerID);
  return layer?.label || '';
};

export const getLoadNumber = (
  assetsKeyedById: Map<string, Asset>,
  fields: {
    [fieldId: string]: any;
  } | undefined,
  dataIndex: string,
  parents,
  parentSlot,
) => {
  const parentSlotType = parentSlot?.type || null;

  const assetId = (parentSlotType && parents && parents[parentSlotType]) || null;
  const parentAsset = assetsKeyedById.get(assetId);
  const label = assetId && parentAsset?.label;

  if (dataIndex === 'load_number') {
    return label || fields?.load_number || '';
  }

  return label || '';
};

export const getLayerPath = (
  layersKeyedById: Map<string, Layer>,
  id?: string | null,
): Array<Layer> => {
  if (!id) return [];
  const currentLayer = layersKeyedById.get(id);
  if (!currentLayer) return [];
  return [
    ...getLayerPath(layersKeyedById, currentLayer.parentID),
    currentLayer,
  ];
};

export const getLayerPathFromLayerID = (
  layersKeyedById: Map<string, Layer>,
  id: string | undefined,
) => {
  if (!id) return '';
  let currentLayer = layersKeyedById.get(id);
  if (!currentLayer) return 'Not Found';
  let output = currentLayer.label;
  currentLayer = layersKeyedById.get(currentLayer.parentID as string);
  if (!currentLayer) return 'Not Found';

  while (currentLayer) {
    output = `${currentLayer.label} > ${output}`;
    currentLayer = layersKeyedById.get(currentLayer.parentID as string);
  }

  return output;
};