import { AssetType } from '@ynomia/core/dist/blueprint';
import { StatusChangeAction } from '@ynomia/core/dist/telemetry';
import client from './Client';

export async function fetchAssets(
  tenant: string,
  projectCode: string,
  assetTypes: Array<AssetType>,
  modifiedAfter?: Date | null,
) {
  const assetTypeTypes: string[] = assetTypes?.map((t: AssetType) => t.id) || [];

  try {
    const result = await client.server.post(
      `/scratch/telemetry/search/${tenant}/${projectCode}`,
      {
        assetType: assetTypeTypes,
        modifiedAfter: modifiedAfter
          ? new Date(modifiedAfter).toISOString()
          : undefined,
        collections: {
          observations: true,
        },
      },
    );
    return result.data.value;
  } catch (error) {
    throw error;
  }
}

export async function updateAsset(
  tenant: string,
  projectCode: string,
  assetId: string,
  payload: object,
) {
  try {
    await client.server.put(
      `/scratch/project/${tenant}/${projectCode}/id/${assetId}`, payload,
    );
  } catch (error) {
    throw error;
  }
}

export async function updateAssetStatus(
  tenant: string,
  projectCode: string,
  payload: { [key: string]: any },
) {
  const {
    assetId,
    statusId,
    date,
    isBackdated,
  } = payload;

  const action: StatusChangeAction = {
    action: 'STATUS_CHANGE',
    date: date || new Date(),
    isBackdated,
    tenant: tenant!,
    project: projectCode!,
    assetID: assetId,
    target: statusId,
  };

  try {
    await client.server.post(
      '/scratch/telemetry/action', action,
    );
  } catch (error) {
    throw error;
  }
}

export async function createAsset(
  tenant: string,
  projectCode: string,
  payload: object,
) {
  try {
    await client.server.post(
      `/scratch/project/${tenant}/${projectCode}/id`, payload,
    );
  } catch (error) {
    throw error;
  }
}

export async function archiveAsset(
  tenant: string,
  projectCode: string,
  assetId: string,
) {
  try {
    await client.server.delete(
      `/scratch/project/${tenant}/${projectCode}/id/${assetId}`,
    );
  } catch (error) {
    throw error;
  }
}
