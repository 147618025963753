import React, { FC } from 'react';
import styles from './styles.module.less';

interface Props {
  color: string
  isRowSelected: boolean
}

const ColorIndicator: FC<Props> = ({ color, isRowSelected }) => {
  return (
    <div
      className={styles.colorIndicator}
      style={{
        borderColor: color,
        backgroundColor: isRowSelected ? color : 'white',
        border: `2px solid ${color}`,
      }}
    />
  );
};
export default ColorIndicator;
