import {
  SET_IS_LOADING_PERCENTAGE,
  SET_IS_TWIN_LOADING,
  SET_IS_TWIN_READY,
} from './actionsTypes';
import { ContextDispatch } from '../config/types';

export const setIsTwinReady = async (
  isTwinsReady: boolean,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SET_IS_TWIN_READY,
    payload: isTwinsReady,
  });
};

export const setIsTwinLoading = async (
  isTwinLoading: boolean,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SET_IS_TWIN_LOADING,
    payload: isTwinLoading,
  });
};

export const setIsLoadingPercentage = (
  loadingPercentage: number,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SET_IS_LOADING_PERCENTAGE,
    payload: loadingPercentage,
  });
};
