import { AssetPageLayout, ContextDispatch } from '../config/types';
import React, { createContext, useContext, useReducer } from 'react';
import { INITIAL_STATUS_TRACKING_LAYOUT } from '../config/constants';
import LocalStorageReducer from '../reducers/localStorage';


export type LocalStorageStateType = {
  assetPageLayout: AssetPageLayout;
};

const initialState: LocalStorageStateType = {
  assetPageLayout: INITIAL_STATUS_TRACKING_LAYOUT,
};
export const LocalStorageContext = createContext({
  state: initialState,
  dispatch: (() => {}) as ContextDispatch,
});

export const useLocalStorage = (): [LocalStorageStateType, ContextDispatch] => {
  const { state, dispatch } = useContext(LocalStorageContext);
  return [state, dispatch];
};

export const LocalStorageProvider = ({ children }) => {
  const [state, dispatch] = useReducer(LocalStorageReducer, initialState);

  return (
    <LocalStorageContext.Provider
      value={{
        state: state,
        dispatch: dispatch,
      }}
    >
      {children}
    </LocalStorageContext.Provider>
  );
};
