import { Blueprint } from '@ynomia/core/dist/project/blueprint';
import client from './Client';

export async function fetchBlueprint(tenant: string, projectCode: string) {
  try {
    const result = await client.server.get(
      `/scratch/project/blueprint/${tenant}/${projectCode}`,
      {},
    );
    return result.data.value as Blueprint;
  } catch (error) {
    throw error;
  }
}

export async function update(
  tenant: string,
  projectId: string,
  projectCode: string,
  blueprintUpdate: object,
) {
  try {
    const result = await client.server.patch(
      `/scratch/project/blueprint/${tenant}/${projectCode}`,
      blueprintUpdate,
    );

    await client.server.bootstrap(projectId);
    return result.data.value;
  } catch (error) {
    throw error;
  }
}

export async function sync(
  tenant: string,
  projectCode: string,
  updateTenant: string,
  updateProject: string,
  slices: Array<string>,
  action: 'plan' | 'apply',
) {
  try {
    const result = await client.server.patch(
      `/scratch/project/blueprint/${tenant}/${projectCode}/sync`,
      {
        updateTenant,
        updateProject,
        slices,
        action,
      },
    );
    return result.data.value;
  } catch (error) {
    throw error;
  }
}

export async function clone(
  sourceTenant: string,
  sourceProject: string,
  newTenant: string,
  newProject: string,
  newName: string,
) {
  try {
    const result = await client.server.post(
      `scratch/project/blueprint/${sourceTenant}/${sourceProject}/clone`,
      { newTenant, newProject, newName },
    );
    return result.data.value;
  } catch (error) {
    throw error;
  }
}
