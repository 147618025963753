import { ContextDispatchParam } from '../config/types';
import { LocalStorageStateType } from '../context';
import { SET_STATUS_TRACKING_LAYOUT } from '../actions/actionsTypes';

export default (
  state: LocalStorageStateType,
  action: ContextDispatchParam,
): LocalStorageStateType => {
  switch (action.type) {
    case SET_STATUS_TRACKING_LAYOUT:
      return {
        ...state,
        assetPageLayout: action.payload,
      };
    default:
      return state;
  }
};
