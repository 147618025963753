import { getAssetTypes, getCurrentFeatureFilters } from '..';
import { getFilteredAssets, getFilteredAssetsContext } from './';
import { createSelector } from 'reselect';
import { getAssetsKeyedByStatus } from '../../utils';

const getFilteredAssetsForSupplierSummary = createSelector(
  [
    getCurrentFeatureFilters,
    getFilteredAssetsContext,
  ],
  (
    currentFeatureFilters,
    filteredAssetsContext,
  ) => {
    const {
      searchTags,
      searchText,
      dateFilter,
      typeFilter,
      assetStatusFilter,
      observationTypeFilter,
      tableFilter,
      layerChildFilter,
      assetLayerFilter,
      multiSearchModeFilter,
    } = currentFeatureFilters;
    if (
      !searchTags &&
        !dateFilter.length &&
        !typeFilter &&
        !assetLayerFilter &&
        !assetStatusFilter?.ids?.length &&
        !observationTypeFilter.size
    ) {
      return filteredAssetsContext.assetsArray;
    }

    return getFilteredAssets(filteredAssetsContext, {
      searchTags,
      searchText,
      dateFilter,
      typeFilter,
      assetLayerFilter,
      assetStatusFilter,
      observationTypeFilter,
      tableFilter,
      layerChildFilter,
      multiSearchModeFilter,
    });

  },
);

export const getAssetsKeyedByStatusForSupplierSummary = createSelector(
  [getAssetTypes, getFilteredAssetsForSupplierSummary],
  getAssetsKeyedByStatus,
);