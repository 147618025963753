import { SET_FETCHING_LAYERS, SET_LAYERS } from './actionsTypes';
import { ContextDispatch } from '../config/types';
import { RequestError } from '@ynomia/client';
import { fetchLayers } from '../services';
import { notification } from '../antdProvider';

export const setLayersLoading = (status: boolean, dispatch: ContextDispatch) =>
  dispatch({ type: SET_FETCHING_LAYERS, payload: status });

export const getLayers = async (
  tenant: string,
  projectCode: string,
  dispatch: ContextDispatch,
) => {
  setLayersLoading(true, dispatch);
  try {
    const res = await fetchLayers(tenant, projectCode);
    dispatch({
      type: SET_LAYERS,
      payload: new Map(res.map(layer => [layer.id, layer])),
    });
  } catch (error) {
    const { status, body } = error as RequestError;

    if (status || body) {
      notification.error({
        message: status,
        description: body?.msg || '',
      });
    }
  }
  setLayersLoading(false, dispatch);
};
