import dayjs, { Dayjs } from 'dayjs';
import client from '../services/Client';
import dayjsRelativeTime from 'dayjs/plugin/relativeTime';
import dayjsTimezone from 'dayjs/plugin/timezone';
import dayjsUtc from 'dayjs/plugin/utc';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';

dayjs.extend(dayjsUtc);
dayjs.extend(dayjsRelativeTime);
dayjs.extend(dayjsTimezone);
dayjs.extend(isToday);
dayjs.extend(isYesterday);

type DateFormat = Date | string | number;

export const ISO_DATE_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss';

export const getProjectTimezone = () =>
  client.cache.current.bootstrap?.project?.calendar?.timezone?.timezone;


/**
 * Determines the time to a certain date (past or future) in plain english.
 * @param {Date | number?} timestamp
 * @param {string?} fallback: Optionally used if `timestamp` is undefined.
 * @return {string} Eg: 27 minutes ago
 */
export const fromNow = (timestamp?: DateFormat, fallback?: string): string => {
  if (!timestamp) {
    return fallback || 'Unknown';
  }
  return dayjs(timestamp).fromNow();
};

export const displayDateInProjectTime = (
  t: Date | string,
  format = 'DD/MM/YYYY',
  timezone?: string,
): string => {
  const PROJ_TZ = timezone ?? getProjectTimezone();
  if (!PROJ_TZ) return dayjs(t).format(format);
  return dayjs(t).tz(PROJ_TZ).format(format);
};

export const fromNowOrDateTime = (
  timestamp?: DateFormat,
  fallback?: string,
): string => {
  const PROJ_TZ = getProjectTimezone();

  if (!timestamp) {
    return fallback || 'Unknown';
  }
  if (dayjs(timestamp).tz(PROJ_TZ).isToday()) {
    const format = '[Today] [at] h:mma';
    return `${displayDateInProjectTime(dayjs(timestamp).toISOString(), format)}`;
  } else if (dayjs(timestamp).tz(PROJ_TZ).isYesterday()) {
    const format = '[Yesterday] [at] h:mma';
    return `${displayDateInProjectTime(dayjs(timestamp).toISOString(), format)}`;
  } else {
    const format = 'MMM DD, YYYY [at] h:mma';
    return displayDateInProjectTime(dayjs(timestamp).toISOString(), format);
  }
};

/**
 * Formats a timestamp into the date only, using the abbreviated month name.
 */
export const formatReadableDateAlt = (timestamp?: DateFormat, timezone?: string): string => {
  if (!timestamp) {
    return 'Unknown';
  }
  const format = 'MMM DD, YYYY';
  if (timezone) {
    return displayDateInProjectTime(dayjs(timestamp).toISOString(), format, timezone);
  }
  return dayjs(timestamp).format(format);
};

export const formatReadableDate = (timestamp?: DateFormat, fallback?: string): string => {
  if (!timestamp) {
    return fallback || 'Unknown';
  }
  const format = 'YYYY-MM-DD';
  return dayjs(timestamp).format(format);
};

export const displayDateInUTCTime = (t: string | Date, format = 'DD/MM/YYYY'): string => {
  return dayjs(t).utc().format(format);
};


export const formatReadableDateLong = (timestamp?: DateFormat, fallback?: string): string => {
  if (!timestamp) {
    return fallback || 'Unknown';
  }
  const format = 'dddd, Do MMMM YYYY';
  return dayjs(timestamp).format(format);
};

export const formatReadableTimeOfDay = (timestamp?: DateFormat, fallback?: string): string => {
  if (!timestamp) {
    return fallback || 'Unknown';
  }
  const format = 'HH:mm:ss';
  return dayjs(timestamp).format(format);
};

export const formatReadableTime12h = (timestamp?: DateFormat, fallback?: string): string => {
  if (!timestamp) {
    return fallback || 'Unknown';
  }
  const format = 'h:mma';
  return dayjs(timestamp).format(format);
};

export const formatReadableDateAndTime = (timestamp?: DateFormat, fallback?: string): string => {
  if (!timestamp) {
    return fallback || 'Unknown';
  }
  const format = 'MMM DD, YYYY [at] h:mma';
  return dayjs(timestamp).format(format);
};

/**
 * Determines the date and time of a locally generated timestamp, and then returns
 * an updated timestamp (in UTC) after it has been converted into the specified timezone.
 *
 * For example if our local timezone is UTC, the specified timezone is `Australia/Melbourne`,
 * and we have the following timestamp: `2021-01-01 15:00:00Z`, this function will return
 * an updated UTC timestamp that reflects 3:00pm in Australia/Melbourne, not 3:00pm in UTC.
 *
 * @param {Date|string} t: Any locally generated timestamp.
 * @param {Date|string} tz: The timezone to convert this date into (for local time).
 * @return {Date} An updated timestamp which reflects this in the target timezone.
 */
export const convertLocalizedTimeToTimezoneTime = (t: Date | string, tz: string = 'UTC'): Date => {
  const formatted = dayjs(t).format(ISO_DATE_FORMAT);
  return dayjs.tz(formatted, tz).utc().toDate();
};

export const convertLocalizedTimeToProjectTime = (t: Date | string): Date => {
  const PROJ_TZ = getProjectTimezone();
  return convertLocalizedTimeToTimezoneTime(t, PROJ_TZ);
};

/**
 * The opposite of `convertLocalizedTimeToTimezoneTime`.
 *
 * @param {Date|string} t: A timestamp reflecting the real time in the source timezone.
 * String must be provided in iso 8601.
 * @param {Date|string} tz: What timezone the timestamp was captured in.
 * @return {Date} A timestamp reflecting that time, but if it was in the local time.
 */
export const convertTimezoneTimeToLocalizedTime = (t: Date | string, tz: string = 'UTC'): Date => {
  /**
   * This should be `dayjs.tz(date, timezone)` like the moment implementation.
   * However, dayjs gives the wrong date/time when daylight savings is in effect.
   * `dayjs(date).tz(timezone)` is an acceptable workaround as it is equivalent
   * to the former when the input is a date or string in UTC (iso 8601).
   *
   * Source: https://github.com/iamkun/dayjs/issues/1260
   **/

  const formatted = dayjs(t).tz(tz).format(ISO_DATE_FORMAT);
  return dayjs(formatted).utc().toDate();
};

/**
 * Converts the given time and reads it as project time
 * (ie. 10pm 9th Aug in the UK) and converts
 * it so it's numerically the same time in local time
 * (ie. 10pm 9th Aug AEST if you're based in Australia).
 * @returns time
 */
export const convertProjectTimeToLocalizedTime = (t: Date | string): Date => {
  const PROJ_TZ = getProjectTimezone();
  return convertTimezoneTimeToLocalizedTime(t, PROJ_TZ);
};

/**
 * Gets the current project time
 * (ie. 10pm 9th Aug in the UK) and converts
 * it so it's numerically the same time in local time
 * (ie. 10pm 9th Aug AEST if you're based in Australia).
 * @returns time
 */
export const getProjectTodayLocalized = () => {
  return dayjs(
    convertProjectTimeToLocalizedTime(dayjs().toDate()),
  );
};

export const sameDayAsProjectDay = (t: Date | string): boolean => {
  const PROJ_TZ = getProjectTimezone();
  if (!PROJ_TZ) return dayjs(t).startOf('day').isSame(new Date(), 'day');
  return dayjs.tz(t, PROJ_TZ).startOf('day').isSame(new Date(), 'day');
};

export const isTimestampMidnight = (t: Date | string): boolean => {
  const formatted = dayjs(t).format('HHmmss');
  return formatted === '000000';
};

/**
 * Converts a UTC timestamp to the project time, and then check to see if it's
 * perfectly at midnight.
 */
export const isTimestampMidnightInProjectTime = (t: Date | string): boolean => {
  const PROJ_TZ = getProjectTimezone();
  const date = convertTimezoneTimeToLocalizedTime(t, PROJ_TZ || 'UTC');
  const formatted = dayjs(date).format('HHmmss');
  return formatted === '000000';
};

export const disabledDate = (
  current: Dayjs,
  restriction: 'future' | 'past' | undefined,
): boolean => {
  if (restriction === 'future') {
    return current && current <= dayjs().endOf('day');
  }
  if (restriction === 'past') {
    return current && current >= dayjs().endOf('day');
  }
  return false;
};

export const getRangePresets = (): Array<{
  label: string;
  value: [Dayjs, Dayjs];
}> => {
  const lastMonth: number = dayjs().month() - 1;
  const lastWeek: number = dayjs().week() - 1;

  return   [
    { label: 'Today', value: [dayjs(), dayjs()] },
    { label: 'Yesterday', value: [dayjs().add(-1, 'd'), dayjs().add(-1, 'd')] },
    { label: 'This Week', value: [dayjs().weekday(0), dayjs()] },
    { label: 'This Month', value: [dayjs().startOf('month'), dayjs()] },
    { label: 'Last Week', value: [
      dayjs().week(lastWeek).startOf('week'),
      dayjs().week(lastWeek).endOf('week')] },
    { label: 'Last Month', value: [
      dayjs().month(lastMonth).startOf('month'),
      dayjs().month(lastMonth).endOf('month'),
    ] },
  ];
};

export const formatDate = (date: string | Date | undefined, excludeTime?: boolean) => {
  if (!date) {
    return '';
  }
  return excludeTime
    ? `${displayDateInProjectTime(date, 'MMM DD, YYYY')}` :
    `${displayDateInProjectTime(date, 'MMM DD, YYYY [at] h:mma')}`;
};

export const formatObservationDate = (timestamp: string | Date, timezone?: string) =>
  isTimestampMidnightInProjectTime(timestamp)
    // Backdated dates should be shown in the project timezone (if defined)
    ? formatReadableDateAlt(timestamp, timezone)
    // Otherwise show the timestamp in the user's local timezone
    : fromNowOrDateTime(timestamp);

export const isIsoDate = (str) => {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
  const d = new Date(str);
  return d instanceof Date && !isNaN(d.getTime()) && d.toISOString() === str;
};

export const toProjectDate = (localDate: Dayjs) =>
  dayjs(convertLocalizedTimeToProjectTime(localDate.toString()))
    .tz(getProjectTimezone());