import React, { FunctionComponent } from 'react';
import { Error } from '../../components/atoms';
import { Helmet } from 'react-helmet';

const Error500: FunctionComponent = () => {
  return (
    <>
      <Helmet title="500" />
      <Error code={'500'}/>
    </>
  );
};

export default Error500;
