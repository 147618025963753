import { Col, Row } from 'antd';
import React from 'react';
import YnomiaFullLogoDark from '../../../images/ynomia-full-logo-dark.svg?react';
import styles from './styles.module.less';

const Footer = () => {
  return (
    <div className={styles.footerContainer}>
      <div className={styles.inner}>
        <Row>
          <Col span={16}>
            <p>
              &copy; Ynomia - All Rights Reserved.
              Version: {process.env.VITE_GIT_COMMIT_HASH?.substring(0, 7) || 'Dev'}
            </p>
          </Col>
          <Col
            span={8}
            className={styles.logo}
            style={{ justifyContent: 'flex-end', display: 'flex' }}
          >
            <YnomiaFullLogoDark width={200}/>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Footer;
