
import React, { FC } from 'react';
import { Asset } from '../../../../../config/types/assets';
import { ExpandingTimelineType } from '../../../../../hooks/useExpandingTimeline';
import { Field } from '@ynomia/core/dist/blueprint';
import FieldValues from '../../../../atoms/FieldValues';
import { ObservationIcon } from '../../../../atoms';
import { ObservationListItem } from '../../../../../config/types';
import { Tooltip } from 'antd';
import { getContextStores } from '../../../../../context';
import { getObservationFieldSchema } from '../../../../../utils/asset';
import {
  getSelectedAssetType,
} from '../../../../../selectors';
import styles from './styles.module.less';

interface Props {
  observation: ObservationListItem;
  expandedKey: string;
  expandController: ExpandingTimelineType;
  asset: Asset;
}

const ObservationItem: FC<Props> = ({ observation, expandController, expandedKey, asset }) => {
  const isOpened = expandController.expandedRowKeys.includes(expandedKey);

  const contextStores = getContextStores();
  const selectedAssetType = getSelectedAssetType(contextStores);

  const { title, action, description, icon, tooltip, fontColor } = observation;
  const observationRaw = observation.observation_raw;
  const { fields, type } = observationRaw || {};

  const fieldSchema = getObservationFieldSchema(action, selectedAssetType.observations, type);

  const items = (
    <FieldValues
      schema={fieldSchema as Field[]}
      values={fields || {}}
      asset={asset}
      display={{ gutter: [4, 0], labelColSpan: 8, valueColFlex: 'auto' }}
    />
  );

  const labelStyles = fontColor ? styles.fadedLabelContainer : styles.statusLabelContainer;

  return (
    tooltip ? <Tooltip
      title={tooltip}
      placement="topLeft"
    >
      <div style={{ display: 'flex' }}>
        <div className={styles.statusIconContainer}>
          <ObservationIcon name={icon.name} type={icon.type} color="#999"/>
        </div>
        <div className={labelStyles}>
          {title}
        </div>
      </div>
      <div className={styles.descriptionText}>
        {description}
      </div>
    </Tooltip> : <>
      <div style={{ display: 'flex' }}>
        <div className={styles.statusIconContainer}>
          <ObservationIcon name={icon.name} type={icon.type} color={fontColor || ''}/>
        </div>
        <div
          className={action === 'NONE' ? labelStyles : styles.statusLinkContainer}
          onClick={()=> action !== 'NONE' && expandController.toggleRowExpanded(expandedKey)}
        >
          {title}
        </div>
      </div>
      <div className={styles.descriptionText}>
        {description}
      </div>
      {isOpened && <div style={{ paddingLeft: 20 }}>
        {items}
      </div>}
    </>
  );
};

export default ObservationItem;
