import React, { createContext, useContext, useReducer } from 'react';
import { ContextDispatch } from '../config/types';
import { Device } from '@ynomia/core/dist/device';
import DeviceReducer from '../reducers/devices';

export type DeviceStateType = {
  devicesKeyedById: Map<string, Device>
  isFetchingDevices: boolean
};

const initialState: DeviceStateType = {
  devicesKeyedById: new Map(),
  isFetchingDevices: false,
};

export const DeviceContext = createContext({
  state: initialState,
  dispatch: (() => {}) as ContextDispatch,
});

export const useDevice = (): [DeviceStateType, ContextDispatch] => {
  const { state, dispatch } = useContext(DeviceContext);
  return [state, dispatch];
};

export const DeviceProvider = ({ children }) => {
  const [state, dispatch] = useReducer(DeviceReducer, initialState);

  return (
    <DeviceContext.Provider
      value={{
        state: state,
        dispatch: dispatch,
      }}
    >
      {children}
    </DeviceContext.Provider>
  );
};
