import {
  AssetStatus,
  AssetType,
  Field,
  ObservationSchema,
  ProjectUserSummary,
} from '@ynomia/core/dist/blueprint';
import {
  ObservationListItem,
  RawObservationRecord,
  SYSTEM_TYPE,
} from '../config/types/assets';
import {
  formatObservationDate,
  formatReadableDateAlt,
  fromNowOrDateTime,
  getProjectTimezone,
} from '.';
import { DEFAULT_STATUS_COLOR } from '../config/constants';
import { client } from '../services';
import { truncateMacAddress } from '@ynomia/core/dist/utils';

const SYSTEM_TITLE = 'Ynomia';

export const generateAssetObservationList = (
  assetTypesKeyedByType: Map<string, AssetType>,
  assetStatusesKeyedById:  Map<string, AssetStatus>,
  assetType: AssetType,
  projectUserListKeyedById: Map<string, ProjectUserSummary>,
  observations?: Array<RawObservationRecord>,
): Array<ObservationListItem> => {
  if (!observations) return [];

  // Create Asset History Event
  const handleCreateAssetHistoryObservations = (obs): ObservationListItem => {
    {
      const { to, date, created, userID } = obs;
      const user = projectUserListKeyedById.get(userID!)?.name;
      const descriptionLineOne = user ? `by ${user}` : SYSTEM_TITLE;
      const description = `${descriptionLineOne}`;
      const status = to ? assetStatusesKeyedById.get(to) : ({} as AssetStatus);
      const statusColor = status?.color || DEFAULT_STATUS_COLOR;
      const time = fromNowOrDateTime(obs.date || obs.created);

      return {
        observation_id: obs._id,
        observation_raw: obs,
        timestamp: date || created,
        timestamp_human_readable: time,
        server_timestamp: created,
        title: 'Added to Ynomia',
        statusColor,
        description,
        icon: {
          name: 'database-plus',
          type: 'MaterialCommunityIcons',
        },
        action: 'NONE',
        canDelete: false,
        canEditDate: false,
      };
    }
  };

  const handleStatusUpdateObservations = (obs): ObservationListItem => {
    const projTimezone = getProjectTimezone();
    const { to, type, isBackdated, date, created, batchID, userID, isOutOfSequence } = obs;
    const statusName =
      (assetType.statuses?.find(config => config.id === to)?.label) || 'Status Update';
    let description: string;
    let icon: ObservationListItem['icon'];
    const status = to ? assetStatusesKeyedById.get(to) : ({} as AssetStatus);
    const statusColor = status?.color || DEFAULT_STATUS_COLOR;
    let fontColor: string | null = null;
    let tooltip = '';
    let time: string;
    switch (type) {
      case SYSTEM_TYPE.AUTOMATIC_STATUS_UPDATE_OBSERVATION:
        description = `${SYSTEM_TITLE}`;
        icon = {
          name: 'arrow-decision-auto-outline',
          type: 'MaterialCommunityIcons',
        };
        time = fromNowOrDateTime(obs.date || obs.created);
        break;
      case SYSTEM_TYPE.AUTOFILL_STATUS_UPDATE_OBSERVATION:
        time = formatReadableDateAlt(obs.date || obs.created, projTimezone);
        description = 'No Date Available';
        icon = {
          name: 'calendar-question',
          type: 'MaterialCommunityIcons',
        };
        fontColor = '#999';
        break;
      case SYSTEM_TYPE.STATUS_UPDATE_OBSERVATION:
      default:
        const user = projectUserListKeyedById.get(userID!)?.name || 'Admin';
        let descriptionLineOne = `by ${user}`;
        if (batchID) {
          descriptionLineOne = `Bulk Operation ${descriptionLineOne}`;
        }
        description = `${descriptionLineOne}`;
        icon = {
          name: isBackdated ? 'account-arrow-left-outline' : 'account-arrow-right-outline',
          type: 'MaterialCommunityIcons',
        };
        time = formatObservationDate(obs.date || obs.created, projTimezone);
    }
    if (isOutOfSequence) {
      tooltip = 'This status has been greyed out because it was captured "out-of-sequence".'
      + ' A record was created for reporting purposes, but it did not change the current'
      + ' status of the asset.';
      fontColor = '#999';
    }

    return {
      observation_id: obs._id,
      observation_raw: obs,
      timestamp: date || created,
      server_timestamp: obs.created,
      timestamp_human_readable: time,
      title: statusName,
      statusColor,
      description,
      excludeTime: obs.isBackdated,
      isAutoFill: type === SYSTEM_TYPE.AUTOFILL_STATUS_UPDATE_OBSERVATION,
      icon,
      action: 'NONE',
      canDelete:
        client.session.checkFeature('status_tracking.flags.observation_archive', false),
      canEditDate:
        client.session.checkFeature('status_tracking.flags.observation_edit', false),
      fontColor,
      tooltip,
    };
  };

  const handleDeviceLinkUnlinkObservations = (obs): ObservationListItem => {
    const { to, type, deviceUDID, userID, date, created } = obs;
    const isLink = type === SYSTEM_TYPE.DEVICE_ASSIGNED_OBSERVATION;
    let title = isLink ? 'Tag Linked' : 'Tag Unlinked';
    const status = to ? assetStatusesKeyedById.get(to) : ({} as AssetStatus);
    const statusColor = status?.color || DEFAULT_STATUS_COLOR;
    const time = fromNowOrDateTime(obs.date || obs.created);

    if (deviceUDID) {
      // Will return non-mac addresses in their unchanged original format, otherwise
      // returns the last 4 digits of a mac address with a colon.
      title += ` (${truncateMacAddress(deviceUDID, 4, true)})`;
    }
    const user = projectUserListKeyedById.get(userID!)?.name || 'Admin';
    return {
      observation_id: obs._id,
      observation_raw: obs,
      timestamp: date || created,
      server_timestamp: created,
      timestamp_human_readable: time,
      title,
      description: `by ${user}`,
      icon: {
        name: isLink ? 'link-variant' : 'link-variant-off',
        type: 'MaterialCommunityIcons',
      },
      statusColor,
      canDelete: false,
      canEditDate: false,
      action: 'NONE',
    };
  };

  const handleElementSwapObservations = (obs): ObservationListItem => {
    const { to, fields, date, created } = obs;

    const user = projectUserListKeyedById.get(obs.userID!)?.name || 'Admin';
    const status = to ? assetStatusesKeyedById.get(to) : ({} as AssetStatus);
    const statusColor = status?.color || DEFAULT_STATUS_COLOR;
    const time = fromNowOrDateTime(obs.date || obs.created);

    return {
      observation_id: obs._id,
      statusColor,
      observation_raw: obs,
      timestamp: date || created,
      timestamp_human_readable: time,
      server_timestamp: created,
      title: `Swap (${fields?.labelBefore} to ${fields?.labelAfter})`,
      description: `by ${user}`,
      icon: {
        name: 'sync',
        type: 'MaterialCommunityIcons',
      },
      action: 'VIEW_SWAP',
      canDelete: false,
      canEditDate: false,
    };
  };

  const handleDestinationSwapObservations = (obs): ObservationListItem => {
    const { to, date, created, userID } = obs;
    const status = to ? assetStatusesKeyedById.get(to) : ({} as AssetStatus);
    const statusColor = status?.color || DEFAULT_STATUS_COLOR;
    const time = fromNowOrDateTime(obs.date || obs.created);

    const user = projectUserListKeyedById.get(userID!)?.name || 'Admin';
    return {
      observation_id: obs._id,
      observation_raw: obs,
      timestamp: date || created,
      timestamp_human_readable: time,
      statusColor,
      server_timestamp: created,
      title: 'Location Updated',
      description: `by ${user}`,
      icon: {
        name: 'map-pin',
        type: 'Feather',
      },
      action: 'VIEW_DESTINATION_UPDATE',
      canDelete: false,
      canEditDate: false,
    };
  };

  const handleSlotParentChildObservations = (obs): ObservationListItem => {
    const { to, type, userID, date, created, fields } = obs;
    const status = to ? assetStatusesKeyedById.get(to) : ({} as AssetStatus);
    const statusColor = status?.color || DEFAULT_STATUS_COLOR;

    const slot = assetType.slots?.find?.(s => s.id === fields?.slotID);
    const user = projectUserListKeyedById.get(userID!)?.name || 'Admin';
    let descriptionLineOne = `by ${user}`;
    if (fields?.isBulkOperation) {
      descriptionLineOne = `Bulk Operation ${descriptionLineOne}`;
    }
    const description = `${descriptionLineOne}`;
    const externalAssetType = assetTypesKeyedByType.get(fields?.assetType);
    let title = '';
    const time = fromNowOrDateTime(obs.date || obs.created);

    if (type === SYSTEM_TYPE.ADD_TO_PARENT_OBSERVATION) {
      title = `Add To "${fields?.parentLabel || slot?.label || 'Parent'}"`;
    } else if (type === SYSTEM_TYPE.REMOVE_FROM_PARENT_OBSERVATION) {
      title = `Remove From "${fields?.parentLabel || slot?.label || 'Parent'}"`;
    } else {
      title = 'Update Sub-Assets';
    }
    return {
      timestamp: date || created,
      server_timestamp: created,
      observation_id: obs._id,
      timestamp_human_readable: time,
      statusColor,
      observation_raw: {
        ...obs,
        fields: {
          ...(fields || {}),
          slotLabel: slot?.label,
          addedLabelsList: fields?.childLabelsAdded?.length
            ? fields?.childLabelsAdded.join(', ')
            : 'None',
          removedLabelsList: fields?.childLabelsRemoved?.length
            ? fields.childLabelsRemoved.join(', ')
            : 'None',
        },
      },
      title,
      description,
      icon: {
        name: externalAssetType?.display?.icon?.mobile?.name || 'box',
        type: externalAssetType?.display?.icon?.mobile?.type || 'Feather',
      },
      action: obs.type === SYSTEM_TYPE.UPDATE_CHILDREN_OBSERVATION
        ? 'VIEW_CHILDREN_SLOT_OBSERVATION'
        : 'NONE',
      canDelete: false,
      canEditDate: false,
    };
  };

  const handleAssetTypeSpecificObservations = (obs): ObservationListItem | undefined => {
    const { to, type, userID, date, created, batchID } = obs;
    const status = to ? assetStatusesKeyedById.get(to) : ({} as AssetStatus);
    const statusColor = status?.color || DEFAULT_STATUS_COLOR;

    const obsConfig = assetType.observations
      ?.find(config => config.id === type);
    if (!obsConfig) return undefined;
    const user = projectUserListKeyedById.get(userID!)?.name || 'Admin';
    let descriptionLineOne = obsConfig.isAction ? `by ${user}` : 'Ynomia';
    if (batchID) {
      descriptionLineOne = `Bulk Operation ${descriptionLineOne}`;
    }
    const time = fromNowOrDateTime(obs.date || obs.created);

    const description = `${descriptionLineOne}`;
    return {
      timestamp: date || created,
      server_timestamp: created,
      timestamp_human_readable: time,
      observation_id: obs._id,
      observation_raw: obs,
      title: obsConfig?.label || 'Observation',
      description,
      statusColor,
      icon: {
        name: obsConfig?.icon?.mobile?.name || 'eye-outline',
        type: obsConfig?.icon?.mobile?.type || 'MaterialCommunityIcons',
      },
      action: 'OPEN_ASSET_TYPE_OBSERVATION',
      canDelete: true,
      canEditDate: false,
    };
  };

  const observation: Array<ObservationListItem | undefined> = observations.map((obs) => {
    if (obs.type === SYSTEM_TYPE.CREATE_ASSET_OBSERVATION) {
      return handleCreateAssetHistoryObservations(obs);
    }

    const statusObsIds = [
      <string>SYSTEM_TYPE.AUTOMATIC_STATUS_UPDATE_OBSERVATION,
      <string>SYSTEM_TYPE.STATUS_UPDATE_OBSERVATION,
      <string>SYSTEM_TYPE.AUTOFILL_STATUS_UPDATE_OBSERVATION,
    ];
    if (statusObsIds.includes(obs?.type)) {
      return handleStatusUpdateObservations(obs);
    }

    const deviceObsIds = [
      <string>SYSTEM_TYPE.DEVICE_ASSIGNED_OBSERVATION,
      <string>SYSTEM_TYPE.DEVICE_UNASSIGNED_OBSERVATION,
    ];
    if (deviceObsIds.includes(obs?.type)) {
      return handleDeviceLinkUnlinkObservations(obs);
    }

    const elementSwapIds = [
      <string>SYSTEM_TYPE.ELEMENT_SWAPPED_OBSERVATION,
    ];
    if (elementSwapIds.includes(obs.type)) {
      return handleElementSwapObservations(obs);
    }

    const destinationUpdateIds = [
      <string>SYSTEM_TYPE.DESTINATION_UPDATE,
    ];
    if (destinationUpdateIds.includes(obs.type)) {
      return handleDestinationSwapObservations(obs);
    }


    const SLOT_OBSERVATION_TYPE_IDS = [
      <string>SYSTEM_TYPE.ADD_TO_PARENT_OBSERVATION,
      <string>SYSTEM_TYPE.REMOVE_FROM_PARENT_OBSERVATION,
      <string>SYSTEM_TYPE.UPDATE_CHILDREN_OBSERVATION,
    ];
    if (SLOT_OBSERVATION_TYPE_IDS.includes(obs.type)) {
      return handleSlotParentChildObservations(obs);
    }

    return handleAssetTypeSpecificObservations(obs);
  });

  return observation.filter(act => !!act) as Array<ObservationListItem>;
};

export const getObservationFieldSchema = (
  observationAction: string,
  observations: ObservationSchema[] | undefined,
  type: string | undefined,
): Array<Partial<Field>> => {
  switch (observationAction) {
    case 'VIEW_SWAP':
      return [
        {
          label: 'Tag ID (At the time of swap)',
          id: 'deviceUDID',
          fieldType: 'text',
        },
        {
          label: 'Label (Before)',
          id: 'labelBefore',
          fieldType: 'text',
        },
        {
          label: 'Destination (Before)',
          id: 'destinationBefore',
          fieldType: 'text',
        },
        {
          label: 'Label (After)',
          id: 'labelAfter',
          fieldType: 'text',
        },
        {
          label: 'Destination (After)',
          id: 'destinationAfter',
          fieldType: 'text',
        },
      ];
    case 'VIEW_DESTINATION_UPDATE':
      return [
        {
          label: 'Destination',
          id: 'destinationAfter',
          fieldType: 'text',
        },
        {
          label: 'Model Location Identifier',
          id: 'twinIDAfter',
          fieldType: 'text',
        },
        {
          label: 'Previous Destination',
          id: 'destinationBefore',
          fieldType: 'text',
        },
        {
          label: 'Previous Model Location Identifier',
          id: 'twinIDBefore',
          fieldType: 'text',
        },
      ];
    case 'VIEW_CHILDREN_SLOT_OBSERVATION':
      return [
        {
          label: 'Sub-Asset Type',
          id: 'slotLabel',
          fieldType: 'text',
        },
        {
          label: 'Assets Added',
          id: 'addedLabelsList',
          fieldType: 'text',
        },
        {
          label: 'Assets Removed',
          id: 'removedLabelsList',
          fieldType: 'text',
        },
      ];
    default:
      return observations?.find(config => config.id === type)?.fields || [];
  }
};