import React, { useEffect } from 'react';
import { getFile, setFileError } from '../../actions';
import { useParams, useSearchParams } from 'react-router-dom';
import { Alert } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { DownloadFile } from '../../components/connected';
import { Helmet } from 'react-helmet';
import { PageProps } from '../../config/types';
import { Spinner } from '../../components/atoms';
import { getContextStores } from '../../context';

const Files: React.FC<PageProps> = ({ renderMenuHeader }) => {
  const [searchParams] = useSearchParams();
  const { id } = useParams();

  /* Context  */
  const { fileState, fileDispatch } = getContextStores();
  const { imgSrc, error } = fileState;

  const { message, code } = error;
  const loaded = imgSrc || code || message;

  useEffect(() => {
    renderMenuHeader?.(
      <DownloadFile />,
    );
  }, [loaded]);

  useEffect(() => {
    const url = searchParams.get('url');

    if (id) {
      getFile(null, id, fileDispatch);
      return;
    } else if (url) {
      getFile(url, null, fileDispatch);
      return;
    }

    setFileError(
      {
        message: 'No id or URL was provided',
        code: '',
      },
      fileDispatch,
    );

    return () => renderMenuHeader?.(<></>);
  }, []);

  return (
    <Content style={{ display: 'flex', justifyContent: 'center', marginTop: '24px' }}>
      <Helmet title="image" />
      {!loaded && <Spinner />}
      {imgSrc && <img src={imgSrc} alt="YNOMIA HQ" />}
      {(code || message) &&
        <Alert className="code-error" message={code} description={message} type={'error'} />
      }
    </Content>
  );
};

export default Files;
