import { BarChart } from 'chartist';
import styles from './styles.module.less';

let exportFn;

((root: Window & typeof globalThis | undefined, factory) => {
  if (root === undefined && window !== undefined) root = window;
  if (!root) { return; }
  exportFn = factory((root as any).Chartist);
  root['Chartist.plugins.ctButtonLabels'] = exportFn;

})(this, (Chartist) => {
  /**
   * Chartist.js plugin to make labels clickable
   */
  (() => {
    var defaultOptions = {
      labelClass: 'ct-label ct-vertical ct-start',
      labels: undefined,
      reverse: true,
    };

    Chartist.plugins = Chartist.plugins || {};
    Chartist.plugins.ctButtonLabels = (options) => {
      options = Chartist.extend({}, defaultOptions, options);
      const labels = options.labels && [...options.labels];
      if (options.reverse) labels?.reverse();

      return function ctButtonLabels(chart) {
        const $chart = chart.container;
        if (chart instanceof BarChart) {
          chart.on('draw', () => {
            if (!options.onclick) return;

            const labelElements = $chart.getElementsByClassName(options.labelClass);
            let i = 0;
            // Why "for of" is used: https://stackoverflow.com/a/22754453
            for (let elem of labelElements) {
              elem.classList.add(styles.clickableChartLabel);
              if (options.labels) {
                const selectedLabel = labels[i];
                elem.onclick = () => options.onclick?.(selectedLabel);
              } else {
                elem.onclick = () => options.onclick?.(elem.innerHTML);
              }
              i += 1;
            }
          });
        }
      };
    };
  })();

  return Chartist.plugins.ctButtonLabels;
});

export default exportFn;