import { getCurrentFeature, getCurrentFeatureFilters } from './';
import { AssetTableColumn } from '../config/types';
import { createSelector } from 'reselect';
import map from './map';

export const getListViewByFeature = createSelector(
  [map.features],
  (features): Map<string, { [key: string]: Array<AssetTableColumn> }> => {
    const listViewByFeature: Map<string, { [key: string]: Array<AssetTableColumn> }> = new Map();

    if (features) {
      const featuresKeys = Object.keys(features);
      featuresKeys?.forEach((key) => {
        listViewByFeature.set(key, features[key]?.list_view);
      });
    }
    return listViewByFeature;
  },
);

export const getListViewColumns = createSelector(
  [
    getListViewByFeature,
    getCurrentFeature,
    getCurrentFeatureFilters,
  ], (
    listViewByFeature,
    currentFeature,
    currentFeatureFilters,
  ): Array<AssetTableColumn> => {

    const currentFeatureListViews = listViewByFeature.get(currentFeature) || {};
    const { typeFilter } = currentFeatureFilters;
    const listView = currentFeatureListViews[typeFilter];

    if (!listViewByFeature || !listView || !typeFilter) {
      return [
        {
          key: 'label',
          column_order: {
            full_view: 1,
            small_view: 1,
          },
          title: 'Name',
          filter_enabled: false,
          sort_enabled: true,
        },
        {
          key: 'unit_type',
          title: 'Unit Type',
          column_order: {
            full_view: 2,
            small_view: 2,
          },
          filter_enabled: true,
          sort_enabled: true,
        },
        {
          key: 'supplier',
          title: 'Supplier',
          column_order: {
            full_view: 3,
            small_view: 3,
          },
          filter_enabled: true,
          sort_enabled: true,
        },
        {
          title: 'Level',
          key: 'destination',
          column_order: {
            full_view: 4,
            small_view: 4,
          },
          filter_enabled: true,
          sort_enabled: true,
        },
        {
          title: 'Load No.',
          key: 'load_number',
          column_order: {
            full_view: 5,
            small_view: 5,
          },
          filter_enabled: true,
          sort_enabled: true,
        },
        {
          title: 'Status',
          key: 'status',
          column_order: {
            full_view: 6,
            small_view: 6,
          },
          filter_enabled: false,
          sort_enabled: true,
        },
        {
          title: 'Achieved At',
          key: 'statusUpdatedDate',
          column_order: {
            full_view: 7,
            small_view: 7,
          },
          custom: 'formatDate',
          filter_enabled: true,
          sort_enabled: true,
        },
      ];
    }

    if (currentFeature === 'status_tracking') {
      listView[0] = { ...listView[0], fixed: 'left' };
    }

    return listView;
  },
);


export const getCustomColumns = createSelector(
  [getListViewColumns], (listViewColumns) => {
    return listViewColumns.filter(({ custom }) => custom);
  },
);