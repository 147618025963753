import './styles/antd.less';
import {
  AccessRuleProvider,
  AssetProvider,
  AssetUpdateProvider,
  ClientCacheProvider,
  ConfigurationProvider,
  DeviceProvider,
  DigitalTwinProvider,
  FileProvider,
  FilterProvider,
  LayerProvider,
  LocalStorageProvider,
  StatusUpdateProvider,
  UserProvider,
  VisibilityFilterProvider,
} from './context';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import Client from '@ynomia/client';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { auth0Cache } from './services';
import config from './config';

const composeProviders = (...Providers) => Child => props => (
  Providers.reduce((acc, Provider) => (
    <Provider>
      {acc}
    </Provider>
  ), <Child {...props} />)
);

const WrappedApp = composeProviders(
  // Frequent User interaction (Small data, Fast response)
  FilterProvider,
  AssetUpdateProvider,
  StatusUpdateProvider,
  DigitalTwinProvider,
  // Data (Big data, Fast load)
  AssetProvider,
  DeviceProvider,
  LayerProvider,
  UserProvider,
  FileProvider,
  AccessRuleProvider,
  VisibilityFilterProvider,
  // Constants (Doesn't change often)
  LocalStorageProvider,
  ConfigurationProvider,
  ClientCacheProvider,
)(App);


ReactDOM.createRoot(document.getElementById('root')!).render(
  <Auth0Provider
    domain={config.host.auth}
    clientId={config.host.clientId}
    authorizationParams={{
      redirect_uri: window.location.origin,
      /**
       * Do not change the Auth0 audience unless you know what you are doing.
       * @TODO Change the audience name to a proper name.
       */
      audience: 'http://localhost:3000/api',
      scope: Client.constants.DEFAULT_AUTH0_LOGIN_SCOPE,
    }}
    useRefreshTokens={true}
    cache={auth0Cache}
  >
    <React.StrictMode>
      <WrappedApp />
    </React.StrictMode>
  </Auth0Provider>,
);
