import {
  PLAN_UPDATE_ASSET,
  RESET_ERROR,
  RESET_IMPORT_ASSET_UPDATE,
  SET_ASSET_UPDATE_FILE,
  SET_ASSET_UPDATE_FILTERS,
  SET_ASSET_UPDATE_MODE,
  SET_ERROR,
  SET_IS_APPLYING_UPDATE_ASSET,
  SET_IS_PLANNING_UPDATE_ASSET,
  SET_SHOW_CHANGES_ONLY,
  SET_TABLE_FILTERS,
} from '../actions/actionsTypes';
import { AssetUpdateStateType } from '../context';
import { ContextDispatchParam } from '../config/types';
import { getFilterCsv } from '../utils';

export default (
  state: AssetUpdateStateType,
  action: ContextDispatchParam,
): AssetUpdateStateType => {
  switch (action.type) {
    case SET_ASSET_UPDATE_FILE:
      return {
        ...state,
        fileName: action.payload.name,
        fileContents: action.payload.contents,
      };
    case SET_IS_PLANNING_UPDATE_ASSET:
      return {
        ...state,
        isPlanningAsset: action.payload,
      };
    case PLAN_UPDATE_ASSET:
      const fields = getFilterCsv(action.payload);
      return {
        ...state,
        importTime: new Date,
        plannedUpdatedAsset: action.payload,
        filtersAssetUpdate: fields,
        filtersAssetUpdateTable: fields,
      };
    case SET_IS_APPLYING_UPDATE_ASSET:
      return {
        ...state,
        isApplyingAsset: action.payload,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case RESET_IMPORT_ASSET_UPDATE:
      return {
        ...state,
        isPlanningAsset: false,
        isApplyingAsset: false,
        plannedUpdatedAsset: null,
        fileName: null,
        fileContents: null,
      };
    case SET_ASSET_UPDATE_FILTERS:
      return {
        ...state,
        filtersAssetUpdate: action.payload,
      };
    case SET_ASSET_UPDATE_MODE:
      return {
        ...state,
        assetUpdateMode: action.payload,
      };
    case SET_TABLE_FILTERS:
      return {
        ...state,
        filtersAssetUpdateTable: action.payload,
      };
    case SET_SHOW_CHANGES_ONLY:
      return {
        ...state,
        showChangesOnly: action.payload,
      };
    case RESET_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};