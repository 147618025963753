import { getAssetTypes, getCurrentFeatureFilters } from '../';
import { getFilteredAssets, getFilteredAssetsContext } from './';
import { createSelector } from 'reselect';
import { getAssetsKeyedByStatus } from '../../utils';

const getFilteredAssetsForLayerSummary = createSelector(
  [
    getCurrentFeatureFilters,
    getFilteredAssetsContext,
  ],
  (
    currentFeatureFilters,
    filteredAssetsContext,
  ) => {
    const {
      searchTags,
      searchText,
      dateFilter,
      typeFilter,
      assetStatusFilter,
      observationTypeFilter,
      tableFilter,
      supplierFilter,
      multiSearchModeFilter,
    } = currentFeatureFilters;
    if (
      !searchTags &&
        !dateFilter?.length &&
        !typeFilter &&
        !assetStatusFilter?.ids?.length &&
        !observationTypeFilter?.size
    ) {
      return filteredAssetsContext.assetsArray;
    }

    return getFilteredAssets(filteredAssetsContext, {
      searchTags,
      searchText,
      dateFilter,
      typeFilter,
      assetStatusFilter,
      observationTypeFilter,
      tableFilter,
      supplierFilter,
      multiSearchModeFilter,
    });
  },
);

export const getAssetsKeyedByStatusForLayerSummary = createSelector(
  [getAssetTypes, getFilteredAssetsForLayerSummary],
  getAssetsKeyedByStatus,
);