
import { REDIRECT_TO, USER_UNKNOWN } from '../../config/constants';
import React, { useEffect, useRef } from 'react';
import { analytics, authService, cacheObserver, client, getBootstrap } from '../../services';
import { Bootstrap } from '@ynomia/core/dist/blueprint';
import { RedirectLoginResult } from '@auth0/auth0-spa-js';
import { getContextStores } from '../../context';
import { getDefaultRoute } from '../../services';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

const Auth0CallbackView: React.FC = () => {
  /* Context */
  const { clientCacheDispatch } = getContextStores();

  const history = useNavigate();
  const { handleRedirectCallback } = useAuth0();
  // React strict mode runs everything twice, prevent this for this page
  const initialLoad = useRef(true);

  const processLogin = async () => {
    // Talk to Auth0 first to check if the login was successful
    let redirectCallback: RedirectLoginResult;
    try {
      redirectCallback = await handleRedirectCallback();
    } catch (e: any) {
      alert(`${e.error}: ${e.message}`);
      client.logout(USER_UNKNOWN);
      return;
    }

    client.configureBaseURL();
    cacheObserver.initialize({ onChange$: client.cache.onChange$ }, clientCacheDispatch);
    await authService.login();

    // Track successful logins
    analytics.trackEvent('User Login');

    let bootstrap: Bootstrap | undefined;
    try {
      bootstrap = await getBootstrap();
    } catch (e: any) {
      if (e?.body?.msg) alert(e.body.msg);
      client.logout();
      return;
    }

    if (!bootstrap || !bootstrap?.projects || bootstrap?.projects?.length === 0) {
      alert('No projects to show!');
      client.logout();
      return;
    }

    const redirectTo = redirectCallback?.appState?.[REDIRECT_TO] as string | undefined;
    const result = getDefaultRoute(redirectTo);
    if (result === '/login') {
      client.logout();
      return;
    }

    history(result, { replace: true });
  };

  useEffect(() => {
    if (initialLoad.current) {
      initialLoad.current = false;
      try {
        processLogin();
      } catch (e) {
        client.logout();
        return;
      }
    }
  }, []);

  return (
    <></>
  );
};

export default Auth0CallbackView;
