import React, { FC } from 'react';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { getContextStores } from '../../../context';

const DownloadFile: FC = () => {
  /* Context  */
  const { fileState } = getContextStores();
  const { imgSrc } = fileState;

  const downloadImage = () => {
    const element = document.createElement('a');
    if (imgSrc) {
      element.href = imgSrc;
      element.download = 'image.jpg';
      element.click();
    }
  };
  return (
    <div>
      <Button onClick={downloadImage} type="primary" icon={<DownloadOutlined />} size="small">
        Download
      </Button>
    </div>
  );
};
export default DownloadFile;
