import React, { FC, useEffect } from 'react';
import { analytics } from '../../../services';
import { useLocation } from 'react-router-dom';

interface Props {
}

/**
 * This component must be under BrowserRouter to track all the page changes.
 */
const RouteTracker: FC<Props> = () => {
  const location = useLocation();

  useEffect(() => {
    if (['/redirect', '/auth0-callback'].includes(location.pathname)) {
      // Do not log redirects
      return;
    }
    analytics.page(location.pathname);
  }, [location]);

  return (
    <></>
  );
};
export default RouteTracker;
