import { Route, routesKeyedById } from '../config/constants';
import { MenuItem } from '@ynomia/core/dist/blueprint';
import client from './Client';

export function getRoutePathFromKey(key: string, routes: Map<string, Route>) {
  const routePath = routes.get(key)?.path;
  if (routePath) return routePath;
  const path = client.cache.current.bootstrap?.project?.features?.[key]?.customPath;
  if (!path) return undefined;
  return path;
}

/**
 * Fetches the default route by checking the below:
 *  1) First checks if any of the predefined routes are valid
 *  2) Gets the first valid route in features
 *  3) Otherwise, kicks the user to the login screen
 * @param requestedRoute Returns requested route if valid
 * @param features Features from bootstrap
 * @param routes Mapping between the route key and route
 */
export function getDefaultRouteFromProject(
  requestedRoute: string | undefined,
  menu: MenuItem[] | undefined,
  routes: Map<string, Route>,
) {
  if (menu && routes) {

    if (requestedRoute?.startsWith('/files')) {
      return requestedRoute;
    }

    const routeKeysKeyedByPath = new Map(menu.map((menuItem) => {
      const path = routes.get(menuItem.name);
      if (path) return [path.path, menuItem.name];
      const custom = client.cache.current.bootstrap?.project?.features?.[menuItem.name]?.customPath;
      return [custom, menuItem.name];
    }));

    // We test the route keys in a priority order
    // If the predefined keys fail, try the first keys provided by menu
    if (requestedRoute) {
      const validRouteId = routeKeysKeyedByPath.get(requestedRoute);
      if (validRouteId) return requestedRoute;
    }

    const path = menu
      .map(menuItem => getRoutePathFromKey(menuItem.name, routes))
      .find(p => p);
    if (path) return path;
    return '/404';
  }

  return '/login';
}

/**
 * Intelligently gets the default route. Will return the requested route if available.
 * @param requestedRoute A route that the user is requesting to access
 */
export function getDefaultRoute(requestedRoute?: string) {
  // Prioritise the redirect route.
  if (requestedRoute?.startsWith('/redirect?')) {
    return requestedRoute;
  }
  const menu = client?.cache?.current.bootstrap?.project?.menu;

  // If user's not logged in, default to the requested path
  // The page will take the user back to login if this fails anyway
  if (!menu && requestedRoute) return requestedRoute;

  return getDefaultRouteFromProject(requestedRoute, menu, routesKeyedById);
}
