import client from './Client';
import config from '../config';

export async function fetchUser(userId: string ) {
  try {
    const result = await client.server.get(
      `/users/${userId}`,
      {
        baseURL: config.host.iamApi,
      },
    );
    return result.data.value;
  } catch (error) {
    throw error;
  }
}