import Axios from 'axios';
import config from '../config';
import { report as coreReport } from '@ynomia/core';

/**
 * https://stackoverflow.com/a/40940790/2474359
 * @param disposition
 * @returns file name
 */
const getFileNameFromDisposition = (disposition: string) => {
  let fileName = 'unknown.txt';
  if (disposition && disposition.indexOf('attachment') !== -1) {
    var fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    var matches = fileNameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      fileName = matches[1].replace(/['"]/g, '');
    }
  }

  return fileName;
};

export const exportReport = async (
  reportsConfig: coreReport.ReportConfigV2,
) => {
  const fetchFromURL = new URL(`${config.host.api}/reports/v2/blueprint-report`);
  const Authorization = 'Bearer ' + localStorage.getItem('access_token');

  Object.keys(reportsConfig).forEach((param) => {
    fetchFromURL.searchParams.append(param, reportsConfig[param]);
  });

  try {
    const response = await Axios.get(fetchFromURL.href, { headers: { Authorization } });
    const disposition = response.headers['content-disposition'];
    const fileName = getFileNameFromDisposition(disposition);

    return {
      data: response.data,
      fileName,
      type: response.headers['content-type'],
    };

  } catch (error) {
    throw error;
  }
};
