import client from './Client';
import config from '../config';

export async function dismissAnnouncement(
  tenant: string,
  projectCode: string,
  announcementID: string,
  userId: string,
) {
  try {
    const result = await client.server.post(
      `/users/${userId}/dismiss-announcement`,
      {
        tenant,
        project: projectCode,
        announcementID,
      },
      {
        baseURL: config.host.iamApi,
      },
    );
    return result.data.value;
  } catch (error) {
    throw error;
  }
}