import { ClientCacheStateType } from '../context';
import { ContextDispatch } from '../config/types';
import { SET_CLIENT_CACHE } from './actionsTypes';

export const setClientCache = async (
  clientCache: ClientCacheStateType['clientCache'],
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SET_CLIENT_CACHE,
    payload: clientCache,
  });
};
