import React, { FC } from 'react';
import styles from './styles.module.less';

interface Props {
  name: string
}

const LayoutIcon: FC<Props> = ({ name }) => {
  const icon = () => {
    switch (name) {
      case 'thirds':
        return <>
          <div className={styles.rectangle} style={{ flex: 1 }}/>
          <div className={styles.rectangle} style={{ flex: 1 }}/>
          <div className={styles.rectangle} style={{ flex: 1 }}/>
        </>;
      case 'full':
        return <div className={styles.rectangle} style={{ flex: 1 }}/>;
      case 'default':
      default:
        return <>
          <div className={styles.rectangle} style={{ flex: 2 }}/>
          <div className={styles.rectangle} style={{ flex: 1 }}/>
        </>;
    }
  };

  return <div className={styles.container}>{icon()}</div>;
};

export default LayoutIcon;
