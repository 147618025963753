import { SET_FETCHING_LAYERS, SET_LAYERS } from '../actions/actionsTypes';
import { ContextDispatchParam } from '../config/types';
import { LayerStateType } from '../context';

export default (
  state: LayerStateType,
  action: ContextDispatchParam,
): LayerStateType => {
  switch (action.type) {
    case SET_LAYERS:
      // Only update the state when there's a layer change
      if (
        state.layersKeyedById?.size === action.payload?.size
        && [...action.payload.entries()].every(([id, newVal]) =>
          state.layersKeyedById.get(id)?.updated === newVal.updated,
        )
      ) return state;
      return {
        ...state,
        layersKeyedById: action.payload,
      };
    case SET_FETCHING_LAYERS:
      return {
        ...state,
        isFetchingLayers: action.payload,
      };
    default:
      return state;
  }
};
