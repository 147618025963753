import { Asset } from '../config/types';
import { AssetType } from '@ynomia/core/dist/blueprint';
import { Dayjs } from 'dayjs';

export const isFilteredByDateFilter = (updated: Date, dateFilter: [Dayjs, Dayjs]) => {
  /**
   * Originally, we converted all dates to Dayjs to do the date comparison.
   * However, this came with a large performance hit.
   * Thus we changed it to compare using their unix epoch value.
   * Make sure the dateFilter has .startsWith('day') and .endsWith('day') precomputed.
   */
  const [start, end] = dateFilter;
  const res = start.valueOf() <= updated.getTime()
  && updated.getTime() <= end.valueOf();
  return res;
};

export const moveObjectToLast = (array: Array<Object>, obj: Object) => {
  if (array.includes(obj)) {
    const fromIndex = array.indexOf(obj);
    const element = array.splice(fromIndex, 1)[0];
    const toIndex = array.length;

    array.splice(toIndex, 0, element);
    return array;
  }
  return array;
};

export const getAssetsKeyedByStatus = (assetTypes: Array<AssetType>, assetsArray: Array<Asset>) => {

  const assetKeyedByStatuses = new Map();

  if (assetTypes && assetsArray?.length) {
    const statusesArray = assetTypes.map(assetType => assetType.statuses!).flat();

    statusesArray?.forEach(({ id }) => {
      const assetsFilteredByStatusId = assetsArray.filter(({ status }) => status === id);
      assetKeyedByStatuses.set(id, assetsFilteredByStatusId);
    });
  }

  return assetKeyedByStatuses as Map<string, Array<Asset>>;
};