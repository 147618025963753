import {
  ACCESS_TOKEN_STORAGE_KEY,
  EXPIRED,
  ID_TOKEN_STORAGE_KEY,
  PROJECT_ID_STORAGE_KEY,
  REFRESH_TOKEN_STORAGE_KEY,
  YNOMIA_CLIENT_SDK_NAME,
} from '../../config/constants';
import { Auth0ContextInterface, User } from '@auth0/auth0-react';
import { InMemoryCache, Server, SessionManager, UnauthenticatedReason } from '@ynomia/client';
import { analytics, getBootstrap } from '../../services';
import { ContextDispatch } from '../../config/types';
import authService from './Auth';
import cacheObserver from './CacheObserver';
import config from '../../config';

/**
 * This service abstracts the private npm package, '@ynomia/client'. For further
 * documentation, please visit: https://www.npmjs.com/package/@ynomia/client
 *
 * You use this library to make API requests to the core Ynomia backend services.
 */
export default class YnomiaClient {
  private clientCache: InMemoryCache;

  private clientSession: SessionManager;

  private clientServer: Server;

  constructor() {
    this.clientCache = new InMemoryCache();
    this.clientSession = new SessionManager(this.clientCache);
    this.clientServer = new Server(this.session, this.clientCache);
  }

  /**
   * Initializes the Ynomia Client with a fresh state. This method should be called
   * shortly after the web application first starts up.
   * @return {void}
   */
  async initialize(
    auth0: Auth0ContextInterface<User>,
    clientCacheDispatch: ContextDispatch,
  ): Promise<void> {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
    const idToken = localStorage.getItem(ID_TOKEN_STORAGE_KEY);
    const refreshToken = localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY);
    const projectId = localStorage.getItem(PROJECT_ID_STORAGE_KEY);
    this.clientCache = new InMemoryCache({
      clientName: YNOMIA_CLIENT_SDK_NAME,
      platform: 'web',
      environment: config.environment,
      baseURL: config.host.api,
      auth: {
        accessToken: (accessToken as string),
        idToken: (idToken as string),
        refreshToken: (refreshToken as string),
      },
      projectId,
    });
    this.clientSession = new SessionManager(this.clientCache);
    this.clientServer = new Server(this.session, this.clientCache);

    // Subscribe our own event listeners to Client SDK Observables
    cacheObserver.initialize({ onChange$: this.clientCache.onChange$ }, clientCacheDispatch);
    authService.initialize(
      {
        onEstablish$: this.clientSession.onEstablish$,
        onRefresh$: this.clientSession.onRefresh$,
        onTerminate$: this.clientSession.onTerminate$,
      },
      auth0,
    );

    // Is the user potentially still authenticated in from their previous visit?
    if (this.clientCache.current.auth.isAuthenticated) {
      try {
        await getBootstrap(projectId || undefined);
      } catch (e: any) {
        if (e?.body?.msg) alert(e.body.msg);
        this.logout(EXPIRED);
        return;
      }
    }
  }

  /**
   * Sets the Base URL that we should use based on the user's region and environment selections.
   * @return {void}
   */
  configureBaseURL(): void {
    this.clientCache.assign({ baseURL: config.host.api });
  }

  /**
   * @return {InMemoryCache}
   */
  get cache(): InMemoryCache {
    return this.clientCache;
  }

  /**
   * @return {SessionManager}
   */
  get session(): SessionManager {
    return this.clientSession;
  }

  /**
   * @return {Server}
   */
  get server(): Server {
    return this.clientServer;
  }

  /**
   * Clears an existing user's session from local storage and the application memory.
   * @return {Promise<void>}
   */
  async logout(reason?: UnauthenticatedReason): Promise<void> {
    if (!reason) {
      analytics.trackEvent('User Logout', { reason });
    }
    this.clientSession.destroy(reason);
  }
}
