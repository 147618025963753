/* eslint-disable max-len */
import { createGlobalStyle } from 'styled-components';

/*  CHARTIST TOOLTIP PLUGIN */
export const OverridesStyles = createGlobalStyle`
.body {
  font-family: Source Sans Pro,sans-serif !important;
}

.chartist-tooltip {
  position: absolute;
  display: none;
  min-width: 5em;
  padding: 8px 10px;
  background: black;
  color: #fff;
  text-align: center;
  pointer-events: none;
  z-index: 100;
  transition: opacity 0.2s linear;
  border-radius: 3px;
  transform: translate3d(3px, 4px, 0);
}

.chartist-tooltip.tooltip-show {
  display: inline-block;
}

.chartist-tooltip:before {
  position: absolute;
  bottom: -14px;
  left: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  pointer-events: none;
  border-color: transparent;
  border-top-color: black;
  border-width: 7px;
  margin-left: -8px;
}

.chartist-tooltip.hide {
  display: block;
  opacity: 0;
  visibility: hidden;
}

.ant-tabs-nav-wrap .ant-tabs-nav-list {
  padding-left: 24px;
  padding-bottom: 6px;
}

.ant-modal-mask {
  background: transparent !important;
}

.ant-modal-wrap {
}

.ant-modal {
}

.ant-modal-title {
  font-family: Source Sans Pro,sans-serif !important;
}

.ant-modal-body {
  padding-right: 0 !important;
  padding-left: 0 !important;
  padding-top: 0 !important;
}

.ant-modal-confirm-body-wrapper {
  padding-right: 24px !important;
}

.ant-modal-footer {
  padding-right: 24px !important;
}

.ant-scrolling-effect {
  overflow: auto !important;
}

.react-resizable {
  position: relative;
}
.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+");
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
}

.react-resizable-handle {
  right: 20px;
}

.ant-menu-item-selected {
  color: white !important;
  font-family: Source Sans Pro,sans-serif !important;
}

.ant-layout-sider-children {
  background-color: #1A2734;
  width: inherit;
  position: fixed;
}

.ant-avatar{
  background-color: white !important;
}

.ant-table-column-title {
  font-weight: 500 !important;
}

table {
  color: #666666 !important;
}

body {
  font-family: Source Sans Pro,sans-serif !important;
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-table-column-title {
  font-family: Source Sans Pro,sans-serif !important;
}

.ant-table-cell {
  font-family: Source Sans Pro,sans-serif !important;
}

.ant-card-head-title {
  color: #000000d9 !important;
  font-weight: 500 !important;
  font-size: 0.9rem !important;
  font-family: Source Sans Pro,sans-serif !important;
}

.ant-menu-item-group-title {
  font-family: Source Sans Pro,sans-serif !important;
  font-size: 15px !important;
}

.ct-label {
  font-family: Source Sans Pro,sans-serif !important;
  color: #666666;
}

button {
  font-family: Source Sans Pro,sans-serif !important;
}

.ant-btn-default {
  font-family: Source Sans Pro,sans-serif !important;
  font-size: 15px !important;
  color: #666666;
}

.ant-menu-item-selected {
  font-family: Source Sans Pro,sans-serif !important;
  font-size: 15px;
  width: 100% !important;
  margin-inline: 0px !important;
}

.ant-menu-item {
  font-family: Source Sans Pro,sans-serif !important;
  font-size: 15px !important;
}

.ant-dropdown-trigger {
  font-family: Source Sans Pro,sans-serif !important;
}

.ant-input {
  font-family: Source Sans Pro,sans-serif !important;
}

.ant-collapse .ant-collapse-content {
  color: rgba(0, 0, 0, 0.88);
  background-color: #ffffff;
  border-top: 1px solid #f0f0f0;
}

.ant-collapse>.ant-collapse-item {
  border-bottom: 1px solid #f0f0f0;
}

.ant-collapse .ant-collapse-content>.ant-collapse-content-box {
  padding: 0px 10px;
}

.ant-dropdown-menu-title-content {
  font-family: Source Sans Pro,sans-serif !important;
}

.ant-tabs-tab {
  font-family: Source Sans Pro,sans-serif !important;
  font-size: 16px;
}

.ant-pagination-item {
  font-family: Source Sans Pro,sans-serif !important;
  font-size: 15px;
}

.ant-select-selection-item {
  font-family: Source Sans Pro,sans-serif !important;
  font-size: 15px;
}

.chartist-tooltip {
  font-family: Source Sans Pro,sans-serif !important;
}

.tooltip-show {
  color: white;
  background-color: rgba(0, 0, 0, 0.85);
  font-family: Source Sans Pro,sans-serif !important;
  font-size: 0.9rem !important;
  font-weight: 500;
  border-radius: 0px;
  cursor: pointer;
}

.tooltip-show-animation {
  opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 0.3s;
}

.ant-tooltip-inner {
  font-family: Source Sans Pro,sans-serif !important;
}

.ant-upload-wrapper .ant-upload-list .ant-upload-list-item:hover {
  background-color: #fff;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.ant-alert-banner {
  background-color: #f1f1f1;
  padding: 4px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  align-items: center;
  font-weight: bold;
  text-align: center;
}

.ant-btn-sm {
  font-size: 14px !important;
}

.ant-picker{
  font-family: Source Sans Pro,sans-serif !important;
}
input {
  font-family: Source Sans Pro,sans-serif !important; 
}

.ant-picker-month-btn {
  font-family: Source Sans Pro,sans-serif !important;
}

.ant-picker-year-btn {
  font-family: Source Sans Pro,sans-serif !important;
}

.ant-picker-dropdown{
  font-family: Source Sans Pro,sans-serif !important; 
}

.ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
  visibility: visible !important;
}

.ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
  visibility: visible !important;
}

.ant-picker-panels > *:nth-child(2) {
  display: none !important;
}

.rangeFilter .ant-picker-panel-container {
  width: 416px !important;
}

.ant-picker-footer-extra > div {
  flex-wrap: wrap !important; 
}
.CircularProgressbar-path {
  stroke: #1b55e3 !important;
}

.CircularProgressbar-trail {
  stroke: #f2f2f2 !important;
}
.CircularProgressbar-text {
  fill: #1b55e3 !important;
}

.ant-notification-notice-message{
  font-family: Source Sans Pro,sans-serif !important;
}
.ant-tabs .ant-tabs-tabpane-hidden {
  display: block !important;
  visibility: hidden;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1000;
  overflow: hidden;
}

.reportsDropdownCategoryTabs .ant-tabs-nav-list {
  padding: 0;
  margin: 10px 0 10px 0;
}

.code-error .ant-alert-content {
  display: flex;
  justify-content: center;
}

.userManagementTabs .ant-tabs-nav-list {
  padding-left: 0px;
}

.ant-timeline-item-head {
  background-color: #FAFAFA !important;
}

.listViewContainer table {
  color: #666666 !important;
  transform: rotateX(180deg);
}

.listViewContainer .ant-table-content {
  transform: rotateX(180deg);
}

.accessRulesTableContainer table {
  color: #666666 !important;
  transform: rotateX(180deg);
}

.accessRulesTableContainer .ant-table-content {
  transform: rotateX(180deg);
}

.addNewEventHoverOverride {
  top: 5px;
}

html:has(.addNewEventHoverOverride:hover) .addNewEventHoverOverride {
  color: rgba(0, 0, 0, 0.88);
  background: rgba(0, 0, 0, 0.06);
}

html:has(.addNewEventHoverOverride:active) .addNewEventHoverOverride {
  color: rgba(0, 0, 0, 0.88);
  background: rgba(0, 0, 0, 0.15);
}
`;
