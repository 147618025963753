import {
  getCurrentFeatureFilters,
  getListViewColumns,
} from '..';
import { getDataSourceKeyedByAssetId, getFilteredAssets, getFilteredAssetsContext } from '.';
import { createSelector } from 'reselect';
import { getColumnFilters } from '../../utils';
import map from '../map';

export const getFilteredAssetsForListViewTableFilter = createSelector(
  [
    getCurrentFeatureFilters,
    getFilteredAssetsContext,
  ],
  (
    currentFeatureFilters,
    filteredAssetsContext,
  ) => {
    const {
      searchTags,
      searchText,
      dateFilter,
      typeFilter,
      assetStatusFilter,
      observationTypeFilter,
      assetLayerFilter,
      layerChildFilter,
      supplierFilter,
      multiSearchModeFilter,
    } = currentFeatureFilters;
    return getFilteredAssets(filteredAssetsContext, {
      searchTags,
      searchText,
      dateFilter,
      typeFilter,
      assetStatusFilter,
      observationTypeFilter,
      assetLayerFilter,
      layerChildFilter,
      supplierFilter,
      multiSearchModeFilter,
    });
  },
);

export const getColumnFiltersForAssetsTable = createSelector(
  [
    map.layersKeyedById,
    getCurrentFeatureFilters,
    map.timezone,
    getDataSourceKeyedByAssetId,
    getListViewColumns,
  ],
  (
    layersKeyedById,
    currentFeatureFilters,
    timezone,
    listViewDataSource,
    listViewColumns,
  ) => {
    const { tableSorter, tableFilter } = currentFeatureFilters;
    return getColumnFilters(
      tableFilter,
      tableSorter,
      timezone,
      [...listViewDataSource.values()],
      listViewColumns,
    );
  },
);

export const getFilteredAssetsForListView = createSelector(
  [
    getCurrentFeatureFilters,
    getFilteredAssetsContext,
  ],
  (
    currentFeatureFilters,
    filteredAssetsContext,
  ) => {
    const {
      searchTags,
      searchText,
      dateFilter,
      typeFilter,
      assetStatusFilter,
      observationTypeFilter,
      assetLayerFilter,
      tableFilter,
      layerChildFilter,
      supplierFilter,
      multiSearchModeFilter,
    } = currentFeatureFilters;
    return getFilteredAssets(filteredAssetsContext, {
      searchTags,
      searchText,
      dateFilter,
      typeFilter,
      assetStatusFilter,
      observationTypeFilter,
      assetLayerFilter,
      tableFilter,
      layerChildFilter,
      supplierFilter,
      multiSearchModeFilter,
    });
  },
);

export const getDataSourceForListView = createSelector(
  [
    getFilteredAssetsForListView,
    getDataSourceKeyedByAssetId,
  ],
  (
    filteredAssetsForAssetsTable,
    listViewDataSource,
  ) => {
    return filteredAssetsForAssetsTable.map(a => listViewDataSource.get(a.id)!);
  },
);
