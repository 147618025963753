import client from './Client';

export async function fetchLayers(tenant: string, projectCode: string) {
  try {
    const result = await client.server.get(
      `/scratch/project/${tenant}/${projectCode}/layers`,
      {},
    );
    return result.data.value;
  } catch (error) {
    throw error;
  }
}
