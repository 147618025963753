import { Asset } from '../config/types';
import Papaparse from 'papaparse';

export const generateFile = (
  data,
  filename = `export_${new Date().getTime()}.csv`,
  type = 'text/csv',
) => {
  // Create an invisible A element
  const a = document.createElement('a');
  a.style.display = 'none';
  document.body.appendChild(a);

  // Set the HREF to a Blob representation of the data to be downloaded
  a.href = window.URL.createObjectURL(new Blob([data], { type }));

  // Use download attribute to set set desired file name
  a.setAttribute('download', filename);

  // Trigger the download by simulating click
  a.click();

  // Cleanup
  window.URL.revokeObjectURL(a.href);
  document.body.removeChild(a);
};

export const generateDataWithFilteredAsset = (
  data,
  filteredAssets: Array<Asset>,
): string => {
  if (!data) {
    return '';
  }
  const commonConfig = { delimiter: ',' };

  const labels = filteredAssets.map(({ label }) => label);
  const result = Papaparse.parse(data, { ...commonConfig });
  const jsonData = result.data as Array<Array<string>>;
  const header = result.data[0] as Array<string>;
  const filteredData = jsonData.filter(row => labels.includes(row[0]));

  return Papaparse.unparse(
    [header, ...filteredData],
    { newline: '\r\n' },
  );
};

export const formatReportType = (type: string): string => {
  let formattedType: string = type;

  if (type.includes('_')) {
    const typeWords: Array<string> = type.replace('_', ' ').split(' ');
    formattedType = typeWords
      .map((word) => {
        return word[0].toUpperCase() + word.substring(1);
      })
      .join(' ');
  } else {
    formattedType = type.charAt(0).toUpperCase() + type.slice(1);
  }
  return `${formattedType} Exports`;
};

export const generateCSV = (
  header: Array<string>,
  data: Array<Array<string>>,
) => {
  return Papaparse.unparse(
    [header, ...data],
    { newline: '\r\n' },
  );
};