import { ContextDispatch,
  DataType,
  Layer,
  ToggleAssetStatus,
  ToggleObservation,
} from '../config/types';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/es/table/interface';
import {
  REMOVE_ALL_FILTERS,
  REMOVE_ASSET_LEVEL_FILTER,
  REMOVE_ASSET_STATUS_FILTER,
  REMOVE_CHILD_LAYERS_FILTER,
  REMOVE_FILTER_TYPE_CHANGE,
  REMOVE_OBSERVATION_TYPE_FILTER,
  SELECT_ASSET_STATUS_FILTER,
  SELECT_OBSERVATION_TYPE_FILTER,
  SET_ASSET_LAYER_FILTER,
  SET_CHILD_LAYERS_FILTER,
  SET_DATE_FILTER,
  SET_MULTI_SEARCH_MODE_FILTER,
  SET_SEARCH_TAGS,
  SET_SEARCH_TEXT,
  SET_SUPPLIER_FILTER,
  SET_TABLE_FILTER,
  SET_TABLE_PAGINATION,
  SET_TABLE_SORTER,
  SET_TIME_TRAVEL_DATE,
  SET_TYPE_FILTER,
} from './actionsTypes';
import type { Dayjs } from 'dayjs';

export const setSearchTags = (
  searchTags: Array<string>,
  feature: string,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SET_SEARCH_TAGS,
    payload: {
      feature,
      searchTags,
    },
  });
};

export const setSearchText = (
  searchText: string,
  feature: string,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SET_SEARCH_TEXT,
    payload: {
      searchText,
      feature,
    },
  });
};

export const setDateFilter = (
  dateFilter: [Dayjs, Dayjs] | [],
  feature: string,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SET_DATE_FILTER,
    payload: {
      dateFilter,
      feature,
    },
  });
};

export const setTypeFilter = (
  id: string,
  feature: string,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SET_TYPE_FILTER,
    payload: {
      typeFilter: id,
      feature,
    },
  });
};

export const setTableFilter = (
  tableFilter: Record<string, FilterValue | null>,
  feature: string,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SET_TABLE_FILTER,
    payload: {
      tableFilter,
      feature,
    },
  });
};

export const setTableSorter = (
  tableSorter: SorterResult<DataType>,
  feature: string,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SET_TABLE_SORTER,
    payload: {
      tableSorter,
      feature,
    },
  });
};

export const setTablePagination = (
  pagination: TablePaginationConfig,
  feature: string,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SET_TABLE_PAGINATION,
    payload: {
      pagination,
      feature,
    },
  });
};

export const selectAssetStatusFilter = (
  toggledValue: ToggleAssetStatus,
  id: string,
  feature: string,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SELECT_ASSET_STATUS_FILTER,
    payload: {
      feature,
      toggledValue,
      id,
    },
  });
};

export const selectObservationTypeFilter = (
  toggledValue: ToggleObservation,
  id: string,
  feature: string,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SELECT_OBSERVATION_TYPE_FILTER,
    payload: {
      id,
      toggledValue,
      feature,
    },
  });
};

export const setAssetLayerFilter = (
  layerId: Array<string>,
  feature: string,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SET_ASSET_LAYER_FILTER,
    payload: {
      layerId,
      feature,
    },
  });
};

export const removeAllFilters = (feature: string, dispatch: ContextDispatch) => {
  dispatch({
    type: REMOVE_ALL_FILTERS,
    payload: {
      feature,
    },
  });
};

export const removeFilterTypeChange = (feature: string, dispatch: ContextDispatch) => {
  dispatch({
    type: REMOVE_FILTER_TYPE_CHANGE,
    payload: {
      feature,
    },
  });
};


export const removeAssetStatusFilter = (feature: string, dispatch: ContextDispatch) => {
  dispatch({
    type: REMOVE_ASSET_STATUS_FILTER,
    payload: {
      feature,
    },
  });
};

export const removeObservationTypeFilter = (feature: string, dispatch: ContextDispatch) => {
  dispatch({
    type: REMOVE_OBSERVATION_TYPE_FILTER,
    payload: {
      feature,
    },
  });
};

export const removeAssetLayerFilter = (feature: string, dispatch: ContextDispatch) => {
  dispatch({
    type: REMOVE_ASSET_LEVEL_FILTER,
    payload: {
      feature,
    },
  });
};

export const setSupplierFilter = (
  supplier: Array<string>,
  feature: string,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SET_SUPPLIER_FILTER,
    payload: {
      supplier,
      feature,
    },
  });
};

export const setChildLayerFilter = (
  layer: Layer | undefined,
  feature: string,
  dispatch: ContextDispatch,
) => {
  if (layer?.isRoot) {
    dispatch({
      type: SET_CHILD_LAYERS_FILTER,
      payload: {
        feature,
        childLayerFilter: undefined,
      },
    });
    return;
  }
  dispatch({
    type: SET_CHILD_LAYERS_FILTER,
    payload: {
      feature,
      layer,
    },
  });
};

export const setMultiSearchMode = (
  multiSearchModeFilter: 'or' | 'and',
  feature: string,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SET_MULTI_SEARCH_MODE_FILTER,
    payload: {
      multiSearchModeFilter,
      feature,
    },
  });
};

export const setTimeTravelDate = async (
  timeTravelDate: Dayjs | null,
  feature: string,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SET_TIME_TRAVEL_DATE,
    payload: {
      timeTravelDate,
      feature,
    },
  });
  setDateFilter([], feature, dispatch);
};

export const removeChildLayerFilter = (feature: string, dispatch: ContextDispatch) => {
  dispatch({
    type: REMOVE_CHILD_LAYERS_FILTER,
    payload: {
      feature,
    },
  });
};