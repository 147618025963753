import React, { FC } from 'react';
import styles from './styles.module.less';

interface Props {
  children: React.ReactNode,
  accessoryRight?: React.ReactNode,
  onClick?: React.MouseEventHandler<HTMLDivElement>,
}

const MenuItem: FC<Props> = ({ children, onClick, accessoryRight }) => {
  return (
    <div className={styles.container} onClick={onClick}>
      <div className={styles.childrenContainer}>
        {children}
      </div>
      <div className={styles.accessoryRightContainer}>
        {accessoryRight}
      </div>
    </div>
  );
};

export default MenuItem;
