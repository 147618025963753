import { SET_FILE_ERROR, SET_IMG_SRC } from '../actions/actionsTypes';
import { ContextDispatchParam } from '../config/types';
import { FileStateType } from '../context';

export default (
  state: FileStateType,
  action: ContextDispatchParam,
): FileStateType => {
  switch (action.type) {
    case SET_FILE_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SET_IMG_SRC:
      return {
        ...state,
        imgSrc: action.payload,
      };
    default:
      return state;
  }
};
