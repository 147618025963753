import { Layout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import React, { FC, useEffect, useState } from 'react';
import { getMenuFromBootstrap, getMenuItemsFromBootstrap } from '../../../selectors';
import { CollapseButton } from '../../atoms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IS_MENU_COLLAPSED } from '../../../config/constants';
import { ItemType } from 'antd/es/menu/interface';
import YnomiaLogo from '../../../images/ynomia-full-logo-light.svg?react';
import { getContextStores } from '../../../context';
import styles from './styles.module.less';

const SideMenu: FC = () => {
  /* Context  */
  const contextStores = getContextStores();

  /* Selectors */
  const menuData = getMenuFromBootstrap(contextStores);
  const menuItems = getMenuItemsFromBootstrap(contextStores);
  const isMenuCollapse = localStorage.getItem(IS_MENU_COLLAPSED);
  const menuCollapsed: boolean = isMenuCollapse ?
    JSON.parse(isMenuCollapse) : window.screen.width <= 1024;
  const [collapsed, setCollapsed] = useState<boolean>(menuCollapsed);
  const location = useLocation();
  const selectedKey = menuItems.find(route => route.path === location.pathname);

  const toggleMenu = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    localStorage.setItem(IS_MENU_COLLAPSED, `${collapsed}`);
  }, [collapsed]);

  const processedMenuData = menuData.map((category) => {
    const res: ItemType = {
      key: category.name,
      label: !collapsed && <div className={styles.categories}>{category.name}</div>,
      type: 'group',
      children: category.menu?.map((route) => {
        return {
          key: route?.id,
          icon: <FontAwesomeIcon icon={route?.icon} />,
          label: <Link to={route?.path}>{route?.label}</Link>,
        };
      }),
    };
    return res;
  });

  return (
    <Layout.Sider collapsed={collapsed} onCollapse={value => setCollapsed(value)}>
      <div className={styles.menuLeftHeader}>
        {!collapsed && (
          <div className={styles.logo} id="ynomia-sidebar-logo">
            <Link to={'/'}>
              <YnomiaLogo />
            </Link>
          </div>
        )}
        <CollapseButton collapsed={collapsed} onClick={toggleMenu} />
      </div>
      <Menu
        theme="dark"
        mode="inline"
        items={processedMenuData}
        selectedKeys={[selectedKey?.id || '']}
      />
    </Layout.Sider>
  );
};

export default SideMenu;
