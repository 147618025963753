import { Button, Input, InputRef, Tag, Tooltip, message } from 'antd';
import { CloseCircleFilled, SearchOutlined } from '@ant-design/icons';
import React, { ChangeEvent, FC, useRef } from 'react';
import { notification } from '../../../antdProvider';
import styles from './styles.module.less';

type TagInputProps = {
  className?: string
  style?: React.CSSProperties
  searchTags: string[]
  searchText: string
  addonAfter?: JSX.Element
  onChangeTags: (value: string[]) => void
  onChangeText: (value: string) => void
};

const TagInput: FC<TagInputProps> = ({
  searchTags = [],
  searchText = '',
  addonAfter,
  onChangeText,
  onChangeTags,
  ...props
}) => {
  const inputRef = useRef<InputRef>(null);

  const handleDelete = (tag: string) => {
    const newArr = searchTags.filter(i => i !== tag);
    onChangeTags(newArr);
    message.destroy();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value.replace(/\t/g, '');
    onChangeText(text);
  };

  const handleOnChangeTags = () => {
    if (searchText !== '') {
      onChangeTags([...searchTags, searchText]);
    }
  };

  const handleBlur = () => {
    if (searchText) {
      if (searchTags.includes(searchText)) {
        notification.error({
          message: `${searchText} already exists`,
        });
        return;
      }
      handleOnChangeTags();
      onChangeText('');
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {e.preventDefault();}
    if (e.key === 'Backspace' && !searchText) {
      onChangeTags(searchTags.slice(0, -1));
    }
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && searchText) {
      if (searchTags.includes(searchText)) {
        notification.error({
          message: `${searchText} already exists`,
        });
        return;
      }
      handleOnChangeTags();
      onChangeText('');
    }
  };

  const handleClear = () => {
    onChangeTags([]);
    onChangeText('');
  };

  return (
    <div className={`${styles.inputFilter}`}>
      <div
        className={`${styles.tagInput}`}
        onClick={() => inputRef.current?.focus()}
        {...props}
      >
        {searchTags.map(item =>
          item.length > 20 ? (
            <Tooltip title={item} key={item}>
              <Tag closable onClose={() => handleDelete(item)}>{`${item.slice(0, 20)}...`}</Tag>
            </Tooltip>
          ) : (
            <Tag closable key={item} onClose={() => handleDelete(item)}>
              {item}
            </Tag>
          ),
        )}
        <Input
          ref={inputRef}
          variant="borderless"
          placeholder={'Search by Name, Type, Supplier (if applicable) etc.'}
          className={styles.input}
          value={searchText}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
        />
        {searchTags.length || searchText ? <Tooltip title="clear">
          <span onClick={handleClear}>
            <CloseCircleFilled style={{ color: '#00000073', fontSize: 12, paddingRight: 12 }}/>
          </span>
        </Tooltip> : <></>}
      </div>
      {searchTags.length && addonAfter ? <>{ addonAfter }</> :
      <Button
        className={styles.buttonSearch}
        onClick={() => handleBlur()}
        type="primary"
        icon={<SearchOutlined style={{ fontSize: 13 }}/>}
      />}
    </div>
  );
};

export default TagInput;