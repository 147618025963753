import {
  capitalize,
  displayDateInProjectTime,
  getChanges,
  getKeysFromTheFirstItemOfCollection,
  getLayerPathFromLayerID,
  getMetadata,
  getPlannedAssetComparison,
  isIsoDate,
} from '../utils';
import {
  getAssetsKeyedByLabel,
  getAssetsKeyedByTwinId,
  getProjectName,
  getUserInfo,
} from '.';
import client from '../services/Client';
import { createSelector } from 'reselect';
import { isEqual } from 'lodash';
import map from './map';

const UPDATE_TOOL_CREATE_TITLE = 'Create';
const UPDATE_TOOL_ARCHIVE_TITLE = 'Delete';
const UPDATE_TOOL_BY_TWIN_ID_TITLE = 'Update by Twin ID';
const UPDATE_TOOL_BY_LABEL_TITLE = 'Update by Label';

export const getChangesAssetTableByLabel = createSelector(
  [map.plannedUpdatedAsset, getAssetsKeyedByLabel, map.layersKeyedById],
  (plannedUpdatedAsset, assetsKeyedByLabel, layersKeyedById) => {
    const { byLabel } = plannedUpdatedAsset?.updates || {};
    const updatedByLabel = new Map();

    byLabel?.forEach((updatedAsset) => {
      const { label } = updatedAsset;
      const currentAsset = assetsKeyedByLabel.get(label);
      updatedByLabel.set(label, getChanges(
        currentAsset,
        updatedAsset,
        layersKeyedById,
      ));
    });

    return updatedByLabel as Map<string, Array<{
      key: string;
      currentValue: any;
      isValueChanged: 'updated' | 'new' | 'none';
      newValue: any;
    }>>;
  },
);

export const getAssetUpdateToolExcelData = createSelector(
  [
    map.plannedUpdatedAsset,
    map.timezone,
    map.assetFileName,
    map.assetImportTime,
    map.tenant,
    map.layersKeyedById,
    map.assetUpdateMode,
    getAssetsKeyedByLabel,
    getAssetsKeyedByTwinId,
    getProjectName,
    getUserInfo,
  ],
  (
    plannedUpdatedAsset,
    timezone,
    assetFileName,
    assetImportTime,
    tenant,
    layersKeyedById,
    assetUpdateMode,
    assetsKeyedByLabel,
    assetsKeyedByTwinId,
    projectName,
    userInfo,
  ) => {
    if (!plannedUpdatedAsset) return {};

    const { updates, logging, totalCount, dbAssetTypeCount, unchanged } = plannedUpdatedAsset;
    const keys = [
      'created',
      'archived',
      'byLabel',
      'byTwinID',
      'errors',
      'warnings',
      'info',
      'metadata',
    ];
    const data = { ...updates, ...logging };
    const changed = data.byTwinID.length + data.byLabel.length;

    return keys.map((plannedUpdatedAssetUpdateKey) => {
      const planned = data[plannedUpdatedAssetUpdateKey] || [];

      if (plannedUpdatedAssetUpdateKey === 'created') {
        const flattenPlanned = planned.map((row) => {
          const flattenRow = {
            ...row,
            ...row.fields,
          };
          delete flattenRow.fields;
          return flattenRow;
        });
        return {
          sheet: `${UPDATE_TOOL_CREATE_TITLE} (${flattenPlanned.length})`,
          content: flattenPlanned,
        };
      }

      if (plannedUpdatedAssetUpdateKey === 'archived') {
        const flattenPlanned = planned.map((row) => {
          const flattenRow = {
            ...row,
            ...row.fields,
          };
          delete flattenRow.fields;
          return flattenRow;
        });
        return {
          sheet: `${UPDATE_TOOL_ARCHIVE_TITLE} (${flattenPlanned.length})`,
          content: flattenPlanned,
        };
      }

      if (plannedUpdatedAssetUpdateKey === 'byTwinID'
        || plannedUpdatedAssetUpdateKey === 'byLabel') {
        const plannedContent = planned?.map((plannedAsset) => {
          let formattedPlannedAsset = { ...plannedAsset, ...plannedAsset?.fields };
          const asset = plannedUpdatedAssetUpdateKey === 'byTwinID' ?
            assetsKeyedByTwinId.get(formattedPlannedAsset.twinID)
            : assetsKeyedByLabel.get(formattedPlannedAsset.label);
          const { update } = getPlannedAssetComparison(formattedPlannedAsset, asset);
          const content = {};

          update.forEach(({ key, currentValue, newValue }) => {
            if (key !== 'fields') {
              content[
                `${key === 'layerID' ? 'Layer' : capitalize(key)} [Current value]`
              ] = key === 'layerID' ?
                `${getLayerPathFromLayerID(
                  layersKeyedById,
                  currentValue,
                )} (${currentValue})` : currentValue;
              content[
                `${key === 'layerID' ? 'Layer' : capitalize(key)} [New value]`
              ] = key === 'layerID' ?
                `${getLayerPathFromLayerID(
                  layersKeyedById,
                  newValue,
                )} (${newValue})` : newValue;
            }

          });
          return content;
        });

        return {
          sheet: plannedUpdatedAssetUpdateKey === 'byTwinID' ?
            `${UPDATE_TOOL_BY_TWIN_ID_TITLE}  (${plannedContent.length})`
            : `${UPDATE_TOOL_BY_LABEL_TITLE} (${plannedContent.length})`,
          columns: Object.keys(plannedContent[0] || []).map((key) => {
            return {
              label: key,
              value: key,
            };
          }),
          content: plannedContent,
        };
      }

      if (plannedUpdatedAssetUpdateKey === 'created') {
        return {
          sheet: `${UPDATE_TOOL_CREATE_TITLE} (${planned.length})`,
          columns: Object.keys(planned[0] || []).map((key) => {
            return {
              label: key,
              value: key,
            };
          }),
          content: planned,
        };
      }

      if (plannedUpdatedAssetUpdateKey === 'metadata') {
        return getMetadata({
          tenant,
          projectName,
          userInfo,
          timezone,
          assetUpdateMode,
          fileName: assetFileName,
          unchanged,
          importTime: assetImportTime,
          changed,
          totalCount: totalCount,
          totalBefore: dbAssetTypeCount,
        });
      }

      const flattenPlanned = planned.map((row) => {
        const { msg, metadata } = row || {};
        const { description } = metadata || {};
        const flattenRow = {
          Messages:
            `${msg} (${(planned.length)})${description ? `: ${description}` : ''}`,
          ...row,
          ...row.tags,
        };

        delete flattenRow.metadata;
        delete flattenRow.created;
        delete flattenRow.tags;
        delete flattenRow.msg;
        return flattenRow;
      });

      const flattenPlannedByTable = flattenPlanned.map(
        row => Object.keys(row),
      ).reduce((acc, current) => {
        const x = acc.find(item => isEqual(item, current));
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []).map(tableKeys => flattenPlanned.filter(
        row => isEqual(Object.keys(row), tableKeys)),
      );

      return {
        sheet: plannedUpdatedAssetUpdateKey === 'info' ?
          `Messages (${flattenPlanned.length})`
          : `${capitalize(plannedUpdatedAssetUpdateKey)} (${flattenPlanned.length})`,
        content: flattenPlannedByTable,
      };
    });
  },
);

export const getStatusUpdateToolExcelData = createSelector(
  [
    map.plannedUpdatedStatus,
    map.timezone,
    map.statusFileName,
    map.statusImportTime,
    map.tenant,
    getProjectName,
    getUserInfo,
  ],
  (
    plannedUpdatedStatus,
    timezone,
    fileName,
    importTime,
    tenant,
    projectName,
    userInfo,
  ) => {
    if (!plannedUpdatedStatus) {
      return {};
    }
    const { updates, logging } = plannedUpdatedStatus;
    const updateKeys = updates ? Object.keys(updates) : [];
    const keys = [...updateKeys, 'errors', 'warnings', 'info', 'metadata'];
    const data = { ...updates, ...logging };

    const dateFormat = client.cache.current.bootstrap?.project?.calendar.dateFormat;

    return keys.map((plannedUpdatedStatusKey) => {
      const planned = data[plannedUpdatedStatusKey]?.map((row) => {
        let flattenRow = { ...row };
        if (row.tags) {
          flattenRow = {
            ...row,
            ...row.tags,
          };
          delete flattenRow.tags;
        }
        if (row?.msg) {
          flattenRow = {
            messages: flattenRow.msg,
            ...flattenRow,
          };
          delete flattenRow.msg;
        }
        delete flattenRow.metada;

        Object.keys(flattenRow).forEach(
          (key) => {
            flattenRow[key] = isIsoDate(flattenRow[key]) ?
              displayDateInProjectTime(flattenRow[key], dateFormat) : flattenRow[key];
          },
        );
        return flattenRow;
      }) || [];

      if (plannedUpdatedStatusKey === 'metadata') {
        return getMetadata({
          tenant,
          projectName,
          userInfo,
          timezone,
          fileName,
          importTime,
        });
      }

      let sheet = '';

      if (plannedUpdatedStatusKey === 'created') {
        sheet = `Create (${planned.length})`;
      } else if (plannedUpdatedStatusKey === 'updated') {
        sheet = `Update (${planned.length})`;
      } else if (plannedUpdatedStatusKey === 'info') {
        sheet = `Messages (${planned.length})`;
      } else {
        sheet = `${capitalize(plannedUpdatedStatusKey)} (${planned.length})`;
      }

      return {
        sheet,
        columns: Object.keys(planned[0] || []).map((key) => {
          return {
            label: capitalize(key),
            value: key,
          };
        }),
        content: planned,
      };
    });
  },
);

export const getUpdatedLabels = createSelector(
  [getChangesAssetTableByLabel], (changesAssetTableByLabel) => {
    return [...changesAssetTableByLabel.keys()];
  },
);

export const getAssetTableByLabelKeyedByChanges = createSelector(
  [getChangesAssetTableByLabel, getUpdatedLabels],
  (changesAssetTableByLabel, labels) => {
    const keys = getKeysFromTheFirstItemOfCollection(changesAssetTableByLabel);

    const data = new Map();
    if (changesAssetTableByLabel) {

      keys.forEach((key) => { data.set(key, []); });

      const formatted = labels.map((label) => {
        const changes = changesAssetTableByLabel.get(label)?.filter(({
          isValueChanged,
        }) => { return isValueChanged !== 'none'; });

        const changesMap = changes && new Map(changes.map(change => [change.key, change]));
        return {
          label,
          fieldUpdated: changesMap ? [...changesMap.keys()] : [],
        };
      });

      keys.forEach((key) => {
        formatted.forEach(({ label, fieldUpdated }) => {
          if (fieldUpdated.includes(key)) {
            const currentDataSet = data.get(key);
            data.set(key, [...currentDataSet, label]);
          }
        });
      });
    }
    return data as Map<string, any>;
  },
);

export const getChangesAssetTableByTwinID = createSelector(
  [
    map.plannedUpdatedAsset,
    getAssetsKeyedByTwinId,
    map.layersKeyedById,
    map.filtersAssetUpdateTable,
  ],
  (plannedUpdatedAsset, assetsKeyedByTwinId, layersKeyedById) => {
    const { byTwinID } = plannedUpdatedAsset?.updates || {};
    const updatedByTwinID = new Map();

    byTwinID?.forEach((updatedAsset) => {
      const { twinID } = updatedAsset;
      const currentAsset = assetsKeyedByTwinId.get(twinID);
      updatedByTwinID.set(twinID, getChanges(
        currentAsset,
        updatedAsset,
        layersKeyedById,
      ));
    });

    return updatedByTwinID as Map<string, Array<{
      key: string;
      currentValue: any;
      isValueChanged: 'new' | 'updated' | 'none';
      newValue: any;
    }>>;
  },
);

export const getUpdatedTwinID = createSelector(
  [getChangesAssetTableByTwinID], (changesAssetTableByTwinID) => {
    return [...changesAssetTableByTwinID.keys()];
  },
);

export const getAssetTableByTwinIDKeyedByChanges = createSelector(
  [getChangesAssetTableByTwinID, getUpdatedTwinID],
  (changesAssetTableByTwinID, twinIDs) => {
    const keys = getKeysFromTheFirstItemOfCollection(changesAssetTableByTwinID);

    const data = new Map();
    if (changesAssetTableByTwinID) {

      keys.forEach((key) => { data.set(key, []); });

      const formatted = twinIDs.map((twinID) => {
        const changes = changesAssetTableByTwinID.get(twinID)?.filter(({
          isValueChanged,
        }) => { return isValueChanged !== 'none'; });

        const changesMap = changes && new Map(changes.map(change => [change.key, change]));
        return {
          twinID,
          fieldUpdated: changesMap ? [...changesMap.keys()] : [],
        };
      });

      keys.forEach((key) => {
        formatted.forEach(({ twinID, fieldUpdated }) => {
          if (fieldUpdated.includes(key)) {
            const currentDataSet = data.get(key);
            data.set(key, [...currentDataSet, twinID]);
          }
        });
      });
    }
    return data as Map<string, any>;
  },
);