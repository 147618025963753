import React, { FunctionComponent } from 'react';
import { Error } from '../../components/atoms';
import { Helmet } from 'react-helmet';


const Error404: FunctionComponent = () => {
  return (
    <>
      <Helmet title="404" />
      <Error code={'404'}/>
    </>
  );
};

export default Error404;
