import { AssetPageLayout, ContextDispatch } from '../config/types';
import { SET_STATUS_TRACKING_LAYOUT } from './actionsTypes';

export const setAssetPageLayout = async (
  layout: AssetPageLayout,
  dispatch: ContextDispatch,
) => {
  localStorage.setItem(SET_STATUS_TRACKING_LAYOUT, layout);
  dispatch({
    type: SET_STATUS_TRACKING_LAYOUT,
    payload: layout,
  });
};