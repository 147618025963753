import React, { useState } from 'react';
import { Select } from 'antd';

export type DynamicPicklistColumn = {
  id: string;
  options: Array<{
    label: string;
    value: string;
    filters?: {
      [columnId: string]: string;
    };
  }>;
};

export interface Props {
  columns: Array<DynamicPicklistColumn>;
  value?: string;
  displayDelimiter?: string;
  onValueChange?: (value: string) => void | Promise<void>;
  label?: string;
  labelRight?: string;
  asterisk?: boolean;
  errorMessage?: string;
  confirmText?: string;
  disabled?: boolean;
  resetValuesOnChange?: boolean;
}

const NO_SELECTION_TEXT = 'Select...';
const DEFAULT_DISPLAY_DELIMITER = ', ';

export const segmentedPicklistDisplayValue = (
  columns: Array<DynamicPicklistColumn>,
  selections: { [columnId: string]: string },
  delimiter?: string,
): string => (
  columns
    .map(col => col.options?.find?.(o => o.value === selections[col.id])?.label)
    .filter(label => label !== undefined)
    .join(delimiter || DEFAULT_DISPLAY_DELIMITER)
);


const SegmentedPicklist = ({
  columns,
  value,
  displayDelimiter,
  onValueChange,
  disabled,
  resetValuesOnChange,
}: Props) => {

  const parsedSelections = value && value !== ''
    ? JSON.parse(value)
    : columns.reduce((prev, col) => ({
      ...prev,
      [col.id]: col.options[0]?.value,
    }), {});

  const [tempSelections, setTempSelections] = useState<{ [columnId: string]: string }>({});

  const filterColumnItemBySelections = (filters?: { [key: string]: string }): boolean => {
    const filterKeys = Object.keys(filters || {});
    if (!filters || !filterKeys.length) {
      return true;
    }
    for (let i = 0; i < filterKeys.length; i += 1) {
      const key = filterKeys[i];
      if (tempSelections[key] !== filters[key]) {
        return false;
      }
    }
    return true;
  };

  const pickerOptions = columns.map((col) => {
    const filteredOptions = col.options
      .filter(o => filterColumnItemBySelections(o.filters))
      .map(o => ({
        label: o.label,
        value: o.value,
      }));

    return {
      key: col.id,
      items: filteredOptions,
    };
  }).filter(item => item.items.length > 0);

  const renderValueText = (): any => {
    let text = '';
    if (!value || value === '') {
      text = NO_SELECTION_TEXT;
    } else {
      text = segmentedPicklistDisplayValue(columns, parsedSelections, displayDelimiter);
    }
    return text;
  };

  const onValueChangeHandler = (event) => {
    // On change, reset the index of all the columns to the right
    const newTempSelections = { ...tempSelections };
    if (resetValuesOnChange && tempSelections[event.column] !== event.value) {
      const eventIndex = columns.findIndex(column => column.id === event.column);
      const columnsToReset = columns.slice(eventIndex + 1);
      columnsToReset.forEach((column) => {
        const firstOption = column.options[0];
        newTempSelections[column.id] = firstOption?.value;
      });
    }

    const selections = {
      ...newTempSelections,
      [event.column]: event.value,
    };

    setTempSelections(selections);
    onValueChange?.(JSON.stringify(selections));

  };

  const renderSelects = () => {
    return pickerOptions.map(({ key, items }) => <Select
        key={key}
        defaultValue={renderValueText()}
        onChange={onValueChangeHandler}
        options={items}
        disabled={disabled}
      />,
    );
  };

  return (
    <>{renderSelects()}</>
  );
};

export default SegmentedPicklist;
