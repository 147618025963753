import { Image, Spin } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { ACCESS_TOKEN_STORAGE_KEY } from '../../../config/constants';
import { LoadingOutlined } from '@ant-design/icons';
import styles from './styles.module.less';

interface Props {
  src: string,
  useYnomiaAccessToken?: boolean
  thumbnail?: boolean
}

const ImagePreview: FC<Props> = ({ src, useYnomiaAccessToken, thumbnail }) => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
  const [imgSrc, setImgSrc] = useState<string>('');

  useEffect(() => {
    fetch(src, {
      method: 'GET',
      headers: useYnomiaAccessToken
        ? { Authorization: `Bearer ${accessToken}` }
        : undefined,
    }).then(response => response.blob())
      .then(blob => setImgSrc(URL.createObjectURL(blob)));
  }, []);

  const containerClass = thumbnail
    ? styles.thumbnailImagePreviewContainer
    : styles.imagePreviewContainer;
  const imageClass = thumbnail ? styles.thumbnailImage : styles.image;
  const imageSize = thumbnail ? 30 : 100;
  const imagePreview = thumbnail ? { mask: <div style={{ cursor: 'pointer' }}/> } : {};

  if (!imgSrc) {
    return (
      <div className={containerClass}>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      </div>
    );
  }

  return (
    <div className={containerClass}>
      <Image
        src={imgSrc}
        width={imageSize}
        height={imageSize}
        className={imageClass}
        preview={imagePreview}
      />
    </div>
  );
};

export default ImagePreview;