import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import React, { FC } from 'react';

interface Props {
  isOpened: boolean;
  onClick: () => void;
}

const TimelineIcon: FC<Props> = ({ isOpened, onClick }) => {
  if (isOpened) {
    return <MinusCircleOutlined
      onClick={onClick}
    />;
  }
  return <PlusCircleOutlined
    onClick={onClick}
  />;
};

export default TimelineIcon;
